import React from 'react';
import styled from 'react-emotion';
import { ReactComponent as FacebookLogo } from '../../../svg/FB.svg';
import { ReactComponent as InstagramLogo } from '../../../svg/IG.svg';

const Wrapper = styled('section')`
  margin-right: 0;
  ul {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    label {
      cursor: pointer;
    }
  }

  li {
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  a {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    label {
      margin-left: 1.25rem;
    }
  }

  color: ${({ theme }) => theme.colors.black};
`;

const LogoWrapper = styled('span')`
  width: 1.375rem;
  height: 1.375rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.below.md} {
    margin-right: 0.25rem;
  }
  svg {
    width: 18px;
    max-height: 80%;
  }
`;

const SocialLinks = ({ showLabel = true }) => (
  <Wrapper>
    <ul>
      <li>
        <a
          target="_blank"
          href="https://www.facebook.com/trofe.se/"
          rel="noopener noreferrer"
        >
          <LogoWrapper>
            <FacebookLogo />
          </LogoWrapper>
          {showLabel && <label>Facebook</label>}
        </a>
      </li>
      <li>
        <a
          target="_blank"
          href="https://www.instagram.com/trofe.se/"
          rel="noopener noreferrer"
        >
          <LogoWrapper>
            <InstagramLogo />
          </LogoWrapper>
          {showLabel && <label>Instagram</label>}
        </a>
      </li>
    </ul>
  </Wrapper>
);

export default SocialLinks;
