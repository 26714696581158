import React, { PureComponent } from 'react';
import StoreQuery from './StoreQuery.gql';
import { Query } from 'react-apollo';
import styled from 'react-emotion';
import MaxWidth from '../Layout/MaxWidth';
import StoreDetail from './StoreDetail';

const Wrapper = styled(MaxWidth)`
  margin: 2rem;
`;

export default class Store extends PureComponent {
  componentDidMount = () => {
    console.log(this.props);
  };

  render() {
    const {
      match: {
        params: { id: storeId }
      }
    } = this.props;
    return (
      <Query query={STORE_QUERY} variables={{ id: storeId }}>
        {({ data, loading, error }) => {
          if (loading) return <Wrapper>Loading…</Wrapper>;
          if (error) return 'Something went wrong';

          const { store } = data;

          return (
            <Wrapper>
              {!store ? 'No store found!' : <StoreDetail store={store} />}
            </Wrapper>
          );
        }}
      </Query>
    );
  }
}
