import DynamicRoute from '@jetshop/core/components/DynamicRoute';
import CategoryPreviewQuery from './CategoryPreviewQuery.gql';
import ProductPreviewQuery from './ProductPreviewQuery.gql';
import PaginationProvider from '@jetshop/core/components/Pagination/PaginationProvider';
import ModalProvider from '@jetshop/ui/Modal/ModalProvider';
import ModalRoot from '@jetshop/ui/Modal/ModalRoot';
import PreviewRoute from '@jetshop/core/components/DynamicRoute/PreviewRoute';
import ScrollRestorationHandler from '@jetshop/ui/ScrollRestorationHandler';
import React, { Component } from 'react';
import Helmet from 'react-helmet-async';
import { Route, Switch } from 'react-router-dom';
import Container from './Layout/Container';
import Content from './Layout/Content';
import Footer from './Layout/Footer/Footer';
import Header from './Layout/Header/Header';
import NotFound from './NotFoundPage';
import Theme from './Theme';
import routeQuery from './RouteQuery.gql';

import loadable from '@loadable/component';
import LoadingPage from './LoadingPage';

import GenericError from '@jetshop/ui/ErrorBoundary/Generic';
import Stores from './Stores/Stores';
import Store from './Store/Store';

import { FavoriteListProvider } from './FavoriteList/FavoriteListProvider';
import ArticleFeed from './Theme/Blog/ArticleFeed';
import SingleArticle from './Theme/Blog/SingleArticle';

const LoadableStartPage = loadable(() => import('./StartPage/StartPage'), {
  fallback: <LoadingPage />
});

const LoadableProductPage = loadable(
  () => import('./ProductPage/ProductPage'),
  {
    fallback: <LoadingPage />
  }
);

const LoadableCategoryPage = loadable(
  () => import('./CategoryPage/StandardCategoryPage'),
  {
    fallback: <LoadingPage />
  }
);

const LoadableContentPage = loadable(
  () => import('./ContentPage/ContentPage'),
  {
    fallback: <LoadingPage />
  }
);

const LoadableSearchPage = loadable(() => import('./SearchPage/SearchPage'), {
  fallback: <LoadingPage />
});

class Shop extends Component {
  render() {
    return (
      <GenericError>
        <ModalProvider>
          <Theme>
            <Container>
              <Helmet titleTemplate="%s - Trofé" defaultTitle="Trofé" />

              <FavoriteListProvider>
                <Header />
                <Content>
                  <PaginationProvider defaultProductsPerPage={30}>
                    <Switch>
                      <Route exact path="/" component={LoadableStartPage} />
                      <Route path="/search" component={LoadableSearchPage} />
                      <Route path="/stores" component={Stores} />
                      <Route path="/store/:id" component={Store} />
                      <Route
                        path="/inspiration"
                        component={ArticleFeed}
                        exact
                      />
                      <Route
                        path="/inspiration/:id"
                        component={SingleArticle}
                      />
                      <Route path="/store-finder" component={Stores} />
                      <Route path="/guider" component={ArticleFeed} exact />
                      <Route path="/guider/:id" component={SingleArticle} />
                      <Route
                        path="/preview"
                        component={props => (
                          <PreviewRoute
                            productPage={LoadableProductPage}
                            productQuery={ProductPreviewQuery}
                            categoryQuery={CategoryPreviewQuery}
                            categoryPage={LoadableCategoryPage}
                            StartPage={LoadableStartPage}
                            {...props}
                          />
                        )}
                      />
                      <DynamicRoute
                        routeQuery={routeQuery}
                        productPage={LoadableProductPage}
                        categoryPage={LoadableCategoryPage}
                        contentPage={LoadableContentPage}
                        notFoundPage={NotFound}
                        LoadingPage={LoadingPage}
                      />
                    </Switch>
                  </PaginationProvider>
                </Content>
                <Footer />
                <ModalRoot />
                <ScrollRestorationHandler />
              </FavoriteListProvider>
            </Container>
          </Theme>
        </ModalProvider>
      </GenericError>
    );
  }
}

export default Shop;
