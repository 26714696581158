import React from 'react';
import styled from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';
import { ReactComponent as Cart } from '../../svg/Cart.svg';

const CartWrapper = styled('span')`
  position: relative;
  display: flex;
  margin-right: 8px;
  width: 35px;

  svg {
    height: 24px;
    width: 27px;
    position: relative;
    margin-right: ${({ theme }) => theme.space[1]};
  }

  span {
    position: absolute;
    display: inline-block;
    width: 23px;
    height: 23px;
    top: -12px;
    right: -10px;
    border-radius: 50%;
    font-size: 12px;
    line-height: 2;
    font-weight: normal;
    text-align: center;
    color: ${theme('colors.white')};
    background: ${theme('colors.black')};
    -webkit-box-shadow: 0px 0px 14px -4px #000000;
    box-shadow: 0px 0px 14px -4px #000000;
  }
`;

const CartCounter = ({ counter }) => (
  <CartWrapper>
    <Cart />
    <span>{counter}</span>
  </CartWrapper>
);

export default CartCounter;
