import * as translations from '../translations';
import channelsQuery from './ChannelsQuery.gql';

export default {
  apolloConfig: {
    shopid: process.env.REACT_APP_SHOP_ID || 'trofe',
    graphQLURI:
      process.env.REACT_APP_GRAPHQL_URI || 'https://storeapi.jetshop.se',
    token:
      process.env.REACT_APP_APOLLO_TOKEN ||
      '359fd7c1-8e72-4270-b899-2bda9ae6ef57',
    engineApiKey: process.env.ENGINE_API_KEY || '',
    enableGateway: false,
    channelsQuery,
  },
  // trackingID: process.env.REACT_APP_GA_TRACKING_ID || 'UA-123334166-1',
  trackingID: '',
  tagManagerID: process.env.REACT_APP_GTM_CONTAINER_ID || 'GTM-MJSQFD',
  relewareEnabled: true,
  intl: {
    translations,
    defaultLocale: 'sv',
    options: {
      formats: {
        number: {
          EUR: { style: 'currency', currency: 'EUR' },
          SEK: { style: 'currency', currency: 'SEK' },
          USD: { style: 'currency', currency: 'USD' },
          GBP: { style: 'currency', currency: 'GBP' },
          NKR: { style: 'currency', currency: 'NKR' },
          AUD: { style: 'currency', currency: 'USD' },
        },
      },
    },
  },
  channelOverrides: {},
  disableGeoRedirect: true,
  singleDomainMode: process.env.REACT_APP_SINGLE_DOMAIN_MODE || false,
  schemaExtensions: [],
  preserveRedirect: true,
  structuredData: {
    disableDefaultProductData: false,
  },
  trackers: [],
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
  loginPath: '/login',
  pathsWithNoAuthRequired: ['/login', '/reset-password', '/forgot-password'],
  deprecations: { useOldProductVariants: true },
};
