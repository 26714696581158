import React from 'react';
import styled, { css } from 'react-emotion';
//import theme from '@jetshop/ui/utils/theme';

const Item = styled('div')`
  ${props =>
    props.left
      ? css`
          left: 0;
        `
      : css`
          display: flex;
          right: 0;
          justify-content: flex-end;
          .scrolled & {
            position: relative;
            bottom: 8px;
          }
        `};
  padding: 0;
`;

const SingleMenuItem = styled('div')`
  display: flex;
  align-items: center;
  list-style: none;
  color: ${({ theme }) => theme.colors.grey};
  position: relative;
`;

//<Item className={`MenuItem-${console.log("props", rest) }`}>
//<Item className={`MenuItem-${(rest.left) ? rest.left : rest.right}`}>
export default ({ children, ...rest }) => (
  <Item className={`MenuItem${rest.left ? 'Left' : 'Right'}`}>
    {React.Children.map(children, item => (
      <SingleMenuItem fontSize={1}>{item}</SingleMenuItem>
    ))}
  </Item>
);
