import useFreeShippingCheck from '@jetshop/core/hooks/useFreeShippingCheck';
import t from '@jetshop/intl';
import React from 'react';
import styled from 'react-emotion';
import { Price } from '../Price';
import theme from '@jetshop/ui/utils/theme';

const UntilLimit = styled('div')`
  display: flex;
  align-items: center;

  position: absolute;
  top: 100%;
  right: 0;
  padding-top: 4px;
  color: ${({ theme }) => theme.colors.black};
  white-space: nowrap;

  .scrolled & {
    top: 75%;
  }

  ${theme('below.md')} {
    position: relative;
    top: 0;
    padding-top: 0;
    padding-bottom: 0.25rem;
  }

  svg {
    width: 28px;
    height: 22px;
    margin-right: 8px;
  }

  ${Price.Wrapper} {
    display: inline;
    > * {
      display: inline;
    }
  }
`;

const Reached = styled('div')`
  display: flex;
  align-items: center;

  position: absolute;
  top: 100%;
  right: 0;
  padding-top: 4px;
  color: ${({ theme }) => theme.colors.black};
  white-space: nowrap;

  .scrolled & {
    top: 75%;
  }

  ${theme('below.md')} {
    position: relative;
    top: 0;
    padding-top: 0;
    padding-bottom: 0.25rem;
  }

  svg {
    width: 28px;
    height: 22px;
    margin-right: 8px;
  }
`;

function FreeShipping({ cartTotal, ...rest }) {
  const {
    hasMetLimit,
    untilLimit,
    freeShippingConfigured
  } = useFreeShippingCheck({
    cartTotal
  });

  if (!freeShippingConfigured) return null;

  if (!hasMetLimit)
    return (
      <UntilLimit>
        <span>
          {t.rich(`{price} left until free shipping!`, {
            price: <Price key={untilLimit} price={untilLimit} />
          })}
        </span>
      </UntilLimit>
    );

  return (
    <Reached {...rest}>
      <span>{t('Free for your order')}</span>
    </Reached>
  );
}

export default FreeShipping;
