import CategoryLink from '@jetshop/ui/CategoryLink';
import UIMenuContainer from '@jetshop/ui/Menu/MenuContainer';
import React from 'react';
import styled from 'react-emotion';
import SubMenuWrapper from './SubMenuWrapper';
import theme from '@jetshop/ui/utils/theme';

const Wrapper = styled('div')`
  position: relative;
  & > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  & > section[class*='PosedWrapper'] {
  }
`;

const MainMenuItem = styled('li')`
  a {
    display: inline-block;
    padding: 0.5rem 1rem;
    position: relative;
    font-size: 0.9rem;
    color: #5c5c5c;
    text-decoration: none !important;
    text-transform: uppercase;
    &:after {
      content: '';
      width: 0;
      height: 1px;
      background: ${theme('colors.black')};
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: -3px;
      transition: width ease-in-out 0.2s;
    }
    &.active,
    &:hover {
      color: ${theme('colors.pinkDark')};
      &:after {
        width: 100%;
      }
    }
  }
`;

const MenuContainer = ({ queryData }) => {
  return (
    <UIMenuContainer>
      {props => (
        // When mousing outside the menu, close it onMouseLeave={props.clearActiveCategories}
        //  onMouseLeave={props.clearActiveCategories}
        /* 183 = Inspiration ID */
        /* 186 = Guider ID */
        //
        <Wrapper
          onMouseLeave={props.clearActiveCategories}
          className={'Wrapper'}
        >
          <ul>
            {queryData && queryData.categories
              ? queryData.categories.map(cat => {
                  return cat.id !== 186 ? (
                    cat.id !== 183 ? (
                      <MainMenuItem
                        key={cat.id}
                        onMouseEnter={() => props.setActiveCategory(cat)}
                      >
                        <CategoryLink
                          onClick={props.clearActiveCategories}
                          category={cat}
                        >
                          {cat.name}
                        </CategoryLink>
                      </MainMenuItem>
                    ) : (
                      <MainMenuItem key={cat.id}>
                        <CategoryLink
                          onClick={props.clearActiveCategories}
                          category={cat}
                        >
                          {cat.name}
                        </CategoryLink>
                      </MainMenuItem>
                    )
                  ) : null;
                })
              : null}
          </ul>

          <SubMenuWrapper
            pose={
              props.activeCategories.length > 0 &&
              props.activeCategories[0].subcategories.length > 0
                ? 'open'
                : 'closed'
            }
            activeCategory={props.activeCategories[0]}
            closeNav={props.clearActiveCategories}
          />
        </Wrapper>
      )}
    </UIMenuContainer>
  );
};

export default MenuContainer;
