import React, { Component } from 'react';

const FavoriteListContext = React.createContext();
export class FavoriteListProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      favoriteList: []
    };
  }

  componentDidMount = () => {
    if (typeof window !== 'undefined') {
      if (typeof window.localStorage !== 'undefined') {
        if (localStorage.getItem('favoriteList')) {
          const favoriteList = JSON.parse(localStorage.getItem('favoriteList'));
          this.setState({ favoriteList: favoriteList });
        }
      }
    }
  };

  addToList = product => {
    if (!this.isInList(product.id)) {
      this.setState(
        {
          favoriteList: this.state.favoriteList.concat({ product: product })
        },
        () => {
          if (typeof window !== 'undefined') {
            if (typeof window.localStorage !== 'undefined') {
              localStorage.setItem(
                'favoriteList',
                JSON.stringify(this.state.favoriteList)
              );
            }
          }
        }
      );
    }
  };

  removeFromList = id => {
    if (id === -1) {
      this.setState(
        {
          favoriteList: []
        },
        () => {
          if (typeof window !== 'undefined') {
            if (typeof window.localStorage !== 'undefined') {
              localStorage.setItem('favoriteList', JSON.stringify([]));
            }
          }
        }
      );
    } else {
      let favoriteList = this.state.favoriteList;
      favoriteList = favoriteList.filter(function(obj) {
        return obj.product.id !== id;
      });

      this.setState(
        {
          favoriteList: favoriteList
        },
        () => {
          if (typeof window !== 'undefined') {
            if (typeof window.localStorage !== 'undefined') {
              localStorage.setItem(
                'favoriteList',
                JSON.stringify(favoriteList)
              );
            }
          }
        }
      );
    }
  };

  isInList = productId => {
    let favoriteList = this.state.favoriteList;
    for (var i = 0; i < favoriteList.length; i++) {
      let id = favoriteList[i].product.id;
      if (productId === id) {
        return true;
      }
    }
  };

  render() {
    const { children } = this.props;
    return (
      <FavoriteListContext.Provider
        value={{
          favoriteList: this.state.favoriteList,
          addToList: this.addToList,
          isInList: this.isInList,
          removeFromList: this.removeFromList
        }}
      >
        {children}
      </FavoriteListContext.Provider>
    );
  }
}

export const FavoriteListConsumer = FavoriteListContext.Consumer;
