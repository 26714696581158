import React from 'react';
import styled from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';
import { FavoriteListConsumer } from '../FavoriteList/FavoriteListProvider';

import { ReactComponent as Heart } from '../../svg/Heart.svg';

const FavoritesProvider = styled(FavoriteListConsumer)`
  display: inline-block;
  position: relative;
`;

const HeartWrapper = styled('div')`
  position: relative;
  width: 28px;
  height: 24px;
  margin-right: ${({ theme }) => theme.space[1]};

  svg {
    width: 28px;
    height: 24px;
    fill: none;
    stroke: ${theme('colors.pink')};
  }

  &.has-items {
    svg {
      fill: ${theme('colors.pink')};
      stroke: ${theme('colors.pink')};
    }
  }
`;

const Badge = styled('span')`
  background: ${theme('colors.black')};
  color: #fff;
  width: 23px;
  height: 23px;
  line-height: 23px;
  text-align: center;
  border-radius: 23px;
  position: absolute;
  top: -12px;
  right: -14px;
  font-size: 12px !important;
  -webkit-box-shadow: 0px 0px 14px -4px #000000;
  box-shadow: 0px 0px 14px -4px #000000;
`;

const BadgeNum = () => (
  <FavoriteListConsumer>
    {({ favoriteList }) => <Badge>{favoriteList.length}</Badge>}
  </FavoriteListConsumer>
);

const WishlistCounter = () => (
  <FavoritesProvider>
    {({ favoriteList }) => {
      return (
        <HeartWrapper className={favoriteList.length > 0 && 'has-items'}>
          <Heart />
          {favoriteList.length > 0 && <BadgeNum />}
        </HeartWrapper>
      );
    }}
  </FavoritesProvider>
);

export default WishlistCounter;
