import React, { Component, Fragment } from 'react';
import styled from 'react-emotion';

import t from '@jetshop/intl';
import theme from '@jetshop/ui/utils/theme';

import { ReactComponent as Pin } from '../../svg/map-marker-alt-solid.svg';

import debounce from 'lodash.debounce';

const SearchContainer = styled('div')`
  max-width: 70rem;
  margin: 1rem auto;
  width: 100%;
  flex: 1;
`;

const ListTitle = styled('h2')`
  text-align: center;
  font-size: 1.5rem;
  margin: 1.5rem !important;
  margin-top: 0 !important;
  text-transform: uppercase;
`;

const InputWrapper = styled('div')`
  position: relative;
  width: 100%;
  display: block;
  padding: ${props => (props.narrow ? '0 20px' : '0 40px')};
  margin-bottom: 20px;
  ${theme('below.lg')} {
    padding: 0 20px;
  }
  > input {
    margin: auto;
    width: 100%;
    background: white;
    height: 35px;
    outline: 0;
    padding: 0;
    font-size: 16px;
    border: 0;
    border-bottom: 1px solid black;
    display: flex;
  }
  svg {
    position: absolute;
    right: 50px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.5rem;
  }
`;

const StoresContainer = styled('article')`
  width: 100%;
  background: white;
  padding: 20px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #ddd;

  &:first-child {
    border-top: 1px solid #ddd;
  }

  &:hover {
    background: #eaeaea;
  }
`;
const Left = styled('div')`
  width: 20%;
`;
const Right = styled('div')`
  width: 80%;
`;

const StoreTitle = styled('h2')`
  font-size: 0.85rem !important;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.01rem;
  margin: 0 !important;
  line-height: 1 !important;
`;

const StoreAddress = styled('p')`
  font-size: 0.75rem !important;
  display: block;
  margin-top: 0.6rem !important;
  margin-bottom: 0 !important;
  line-height: 1 !important;
`;

const StoreContact = styled('p')`
  font-size: 0.75rem !important;
  display: block;
  margin-top: 0.6rem !important;
  margin-bottom: 0 !important;
  line-height: 1 !important;
`;

const MapButton = styled('div')`
  font-size: 0.75rem !important;
  cursor: pointer;
  text-align: center;

  &:hover {
    text-decoration: underline;
  }

  .pin {
    width: 25px;
    display: block;
    margin: auto auto 5px auto;
    color: #181818;
  }
`;

const ListContainer = styled('section')`
  overflow-y: scroll;
  width: 100%;
  max-height: 100%;
  flex: 10;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const NoSearchResult = styled('div')`
  font-size: 2rem;
  text-align: center;
  margin: 1.4rem;
`;

const StoresPanel = styled('nav')`
  text-align: right;
  padding: ${props => (props.narrow ? '0 20px' : '0 40px')};
  display: flex;
  align-items: center;
  flex-wrap: ${props => (props.narrow ? 'wrap' : 'nowrap')};
  justify-content: space-between;

  ${theme('below.lg')} {
    padding: 0 20px;
    flex-wrap: wrap;
  }

  ${Left} {
    width: ${props => (props.narrow ? '100%' : '30%')};
    width: auto;
    text-align: ${props => (props.narrow ? 'center' : 'left')};
    margin-bottom: ${props => (props.narrow ? '5px' : '0')};

    ${theme('below.lg')} {
      width: 100%;
      text-align: center;
      margin-bottom: 5px;
    }

    span {
      font-size: 12px;
      display: block;
    }
  }
  ${Right} {
    width: ${props => (props.narrow ? '100%' : '70%')};
    width: auto;
    text-align: ${props => (props.narrow ? 'center' : 'right')};

    ${theme('below.lg')} {
      width: 100%;
      text-align: center;
    }
  }

  label {
    display: inline-block;
    vertical-align: bottom;
    margin-left: 20px;
    cursor: pointer;
    font-size: 14px;
    &:first-of-type {
      margin-left: 0;
    }

    > svg {
      width: 15px;
      display: inline-block;
      vertical-align: bottom;
      margin-right: 5px;
      color: #181818;
    }
  }
`;

class StoresSearch extends Component {
  state = {
    searchedStores: [],
    searchString: ''
  };

  handleSearch = e => {
    let { value } = e.target;
    e.preventDefault();

    if (!value) {
      return this.setState({ searchedStores: [], searchString: value });
    }

    if (value.length >= 2) {
      value = value.trim().toLowerCase();
      const matched = this.props.stores.filter(
        ({ name, city, address1 }) =>
          (name && name.toLowerCase().indexOf(value) > -1) ||
          (city && city.toLowerCase().indexOf(value) > -1) ||
          (address1 && address1.toLowerCase().indexOf(value) > -1)
      );

      return this.setState({
        searchedStores: matched,
        searchString: value
      });
    }

    return;
  };

  debouncSearch = debounce(this.handleSearch, 500);

  render() {
    const { stores, narrow, clickedStoreBox } = this.props;
    const { searchString, searchedStores } = this.state;

    const hasMatch = searchedStores.length > 0;
    const hasSearchValue = !hasMatch && searchString.length > 1;

    return (
      <Fragment>
        <SearchContainer>
          <ListTitle>{t('Hitta din närmaste återförsäljare')}</ListTitle>
          <InputWrapper narrow={narrow}>
            <input
              placeholder={'Sök efter butik'}
              onChange={e => {
                e.persist();
                this.debouncSearch(e);
              }}
            />
          </InputWrapper>
          <StoresPanel narrow={narrow}>
            <Left>
              <span>
                {hasMatch
                  ? searchedStores.length
                  : !hasMatch && hasSearchValue
                  ? '0'
                  : stores.length}
                {t(' Träffar')}
              </span>
            </Left>
          </StoresPanel>
        </SearchContainer>
        <ListContainer>
          {hasMatch ? (
            searchedStores.map(store => (
              <StoresContainer
                id={store.id}
                key={store.id}
                data-lat={store.coordinates.latitude}
                data-lng={store.coordinates.longitude}
                onClick={clickedStoreBox}
              >
                <Left>
                  <MapButton>
                    <span>
                      <Pin className="pin" />
                    </span>
                  </MapButton>
                </Left>
                <Right>
                  <StoreTitle>{store.name}</StoreTitle>
                  <StoreAddress
                    dangerouslySetInnerHTML={{ __html: store.address1 }}
                  />
                  <StoreContact
                    dangerouslySetInnerHTML={{ __html: store.contact }}
                  />
                </Right>
              </StoresContainer>
            ))
          ) : !hasMatch && hasSearchValue ? (
            <NoSearchResult />
          ) : (
            stores.map(store => (
              <React.Fragment key={store.id}>
                <StoresContainer
                  id={store.id}
                  data-lat={store.coordinates.latitude}
                  data-lng={store.coordinates.longitude}
                  onClick={clickedStoreBox}
                >
                  <Left>
                    <MapButton>
                      <span>
                        <Pin className="pin" />
                      </span>
                    </MapButton>
                  </Left>
                  <Right>
                    <StoreTitle>{store.name}</StoreTitle>
                    <StoreAddress
                      dangerouslySetInnerHTML={{ __html: store.address1 }}
                    />
                    <StoreContact
                      dangerouslySetInnerHTML={{ __html: store.contact }}
                    />
                  </Right>
                </StoresContainer>
              </React.Fragment>
            ))
          )}
        </ListContainer>
      </Fragment>
    );
  }
}

export default StoresSearch;
