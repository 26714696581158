import t from '@jetshop/intl';
//import { Above } from '@jetshop/ui/Breakpoints';
import React, { useContext } from 'react';
//import { useQuery } from '@apollo/react-hooks';
import styled, { css } from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';
import FooterLinks from './FooterLinks';
//import MaxWidth from '../MaxWidth';
import SocialLinks from './SocialLinks';
import NewsletterField from '../../Cart/Newsletter/NewsletterField';
import ChannelContext from '@jetshop/core/components/ChannelContext';

import ContactInfo from './ContactInfo';
//import KlarnaImageSrc from '../../../images/klarna-payments.png';
import { ReactComponent as KlarnaLogoBlackSVG } from '../../../svg/KlarnaLogoBlack.svg';
import FooterInfoQuery from './FooterInfoQuery.gql';
import { Query } from 'react-apollo';

const backgroundColor = '#ffffff';

const PoweredByWrapper = styled('div')`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 1.5rem;
  text-align: center;
  ${({ theme }) => theme.below.md} {
    justify-content: space-between;
  }
`;

const Wrapper = styled('section')`
  text-align: left;
  form {
    & > div {
      text-align: center;
    }
    input {
      margin-top: 1rem;
    }
  }
  ${({ theme }) => theme.above.md} {
    background: ${backgroundColor};
    padding: 2rem;
    padding-top: 0;
    margin-top: 2rem;
  }
  ${({ theme }) => theme.below.md} {
    padding-top: 1rem;
    form {
      margin-bottom: 0.5rem;
      & > button {
        margin-top: 1rem;
      }
    }
  }

  h2 {
    font-weight: 600;
    margin-bottom: 1rem;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  td,
  li,
  a,
  p {
    padding-bottom: 0.8rem;
    font-size: 0.9rem;
    line-height: 1.35rem;
    &:last-of-type {
      padding-bottom: 0;
    }
  }
  a {
    display: block;
    color: ${({ theme }) => theme.colors.greyDark};
    text-decoration: none;
    :hover {
      color: ${({ theme }) => theme.colors.black};
      transition: all 0.3s linear;
    }
  }
`;

const WrapFooterNav = styled('div')`
  ${theme('elements.container')};
  padding: 2.75rem 0;

  ${({ theme }) => theme.below.md} {
    padding: 0 ${({ theme }) => theme.sizes.gap};
  }

  display: flex;
  justify-content: space-between;
  flex-direction: row;

  ${({ theme }) => theme.below.md} {
    flex-direction: column;
    justify-content: center;
  }

  h2 {
    ${({ theme }) => theme.below.md} {
      display: none;
    }
  }

  > section {
    margin: 0 2rem 0 0;
    flex: 0 1 25%;
  }
`;

const FooterColumn = styled('div')`
  flex: 0 1 100%;
  h4 {
    margin-bottom: 1rem;
  }
  ${({ theme }) => theme.below.md} {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
  }
`;

const ThemeNewsletter = css`
  color: #fff;
  text-align: center;
`;

const NewsletterWrapper = styled('div')`
  margin-top: 0rem;
  ${({ theme }) => theme.above.md} {
    margin-left: auto;
    text-align: right;
    margin-top: 0;
  }
`;

const FullwidthContainer = styled('div')`
  ${theme('elements.fwContainer')};
  padding: 2.5rem 0;
  background: ${theme('colors.black')};
`;

const NewsContainer = styled('div')`
  ${theme('elements.container')};
`;

// const KlarnaImg = styled('img')`
//   display: block;
//   max-width: 316px;
//   margin: 1.75rem auto 0 auto;
// `;

const JetshopImage = styled('img')`
  display: inline-block;
  max-width: 136px;
  //margin: 1.75rem auto 0 auto;
  ${({ theme }) => theme.below.md} {
    max-width: 126px !important;
    //margin-top: 10px;
  }
`;

const KlarnaLogoBlack = styled(KlarnaLogoBlackSVG)`
  width: 77px;
  height: 17px;
`;

// const KlarnaColumnStyle = css`
//   flex: 1 0 400px;
//   ${({ theme }) => theme.below.md} {
//     flex: 1 1  100%;
//   }
// `;

const KlarnaColumn = styled(FooterColumn)`
  flex: 1 0 400px;
  ${({ theme }) => theme.below.md} {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    p {
      order: 2;
      margin-top: 2rem;
    }
    img {
      max-width: 100%;
      order: 1;
      margin-top: 0;
    }
    div[class*='PoweredByWrapper'] {
      order: 3;
    }
  }
`;

const Footer = () => {
  const { selectedChannel } = useContext(ChannelContext);

  return (
    <Query query={FooterInfoQuery}>
      {({ data, loading, error }) => {
        if (loading) return null;
        if (error) {
          console.log(error);
          return null;
        }
        if (!data.category) return null;

        const result = JSON.parse(
          data.category.content.replace(/(<([^>]+)>)/gi, '')
        );
        return (
          <Wrapper>
            {selectedChannel.id === 1 ? (
              <FullwidthContainer>
                <NewsContainer>
                  <NewsletterWrapper className={ThemeNewsletter}>
                    <NewsletterField />
                  </NewsletterWrapper>
                </NewsContainer>
              </FullwidthContainer>
            ) : null}

            <WrapFooterNav>
              <FooterColumn>
                <h4>Trofé</h4>
                <FooterLinks />
              </FooterColumn>
              <FooterColumn>
                <h4>{t('Contact')}</h4>
                <ContactInfo contactInfo={result.contactInfo} />
              </FooterColumn>

              <FooterColumn>
                <h4>{t('Follow us')}</h4>
                <SocialLinks showLabel={true} />
              </FooterColumn>

              <KlarnaColumn>
                <p>{result.footerText}</p>
                <PoweredByWrapper className={'PoweredByWrapper'}>
                  <KlarnaLogoBlack />
                </PoweredByWrapper>
              </KlarnaColumn>
            </WrapFooterNav>
          </Wrapper>
        );
      }}
    </Query>
  );
};

export default Footer;
