import pagesQuery from './PageListQuery.gql';
import get from 'lodash.get';
import React from 'react';
import { Query } from 'react-apollo';
import { Link } from 'react-router-dom';

const FooterLinks = () => (
  <Query query={pagesQuery}>
    {({ data }) => {
      const pages = get(data, 'pages');

      return pages ? (
        <section>
          <ul>
            {pages.map(page => (
              <li key={page.id}>
                <Link key={page.id} to={page.primaryRoute.path}>
                  {page.name}
                </Link>
              </li>
            ))}
          </ul>
        </section>
      ) : null;
    }}
  </Query>
);

export default FooterLinks;
