import CategoryLink from '@jetshop/ui/CategoryLink';
import React from 'react';
import styled from 'react-emotion';
import posed from 'react-pose';
//import UIMaxWidth from '../../MaxWidth';
import SubMenuSection from './SubMenuSection';
import theme from '@jetshop/ui/utils/theme';

import SubMenuRightContentComp from './SubMenuRightContent';

// Limit the menu to our global MaxWidth
// const MaxWidth = styled(UIMaxWidth)`
//   flex-direction: row;
//   padding-top: 1rem;
//   padding-bottom: 1rem;
//   width: auto;
// `;

// Set the submenu a minimum of 50% of the browser view, but fit the content
// so it doesn't always extend to 100%.

// width: fit-content;
// margin: 0 auto;
// min-width: 50vw;
const ContentFit = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.elements.container};
`;

// We need to put the border here (inside the Wrapper), because if we put it directly
// on the Wrapper it will still be shown when the Wrapper's height is 0
const InnerWrapper = styled('div')`
  width: 100%;
`;

const PosedWrapper = styled(
  posed('section')({
    open: {
      height: 'auto'
    },
    closed: {
      height: '0px',
      overflow: 'hidden'
    }
  })
)`
  display: flex;
  justify-content: center;
  position: absolute;
  right: 0;
  left: 0;
  //top: 53px;
  top: 40px;
  padding-top: 13px;
  z-index: 10;
  ${theme('elements.fwContainer')};
  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    position: absolute;
    //top: -2px;
    top: 11px;
    ${({ theme }) => theme.boxShadow};
  }

  h2 {
    font-size: 1rem;
    font-weight: 600;
    padding: 0 1rem;
    margin-bottom: 0;
    a {
      font-weight: normal;
    }
  }
`;

const SubMenuLeftContent = styled(
  posed('div')({
    open: {
      padding: '2rem 0px'
    },
    closed: {
      padding: '0px 0px'
    }
  })
)`
  flex: 0 1 60%;
`;

const SubMenuRightContent = styled(
  posed('section')({
    open: {
      height: 'auto'
    },
    closed: {
      height: '0px'
    }
  })
)`
  margin: 0 auto;
  overflow: hidden;
`;

const MenuItemsList = styled('ul')`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 160px;
  text-align: left;
  border-right: 1px solid ${({ theme }) => theme.colors.greyMediumDark};
`;

const NoThirdTierItems = styled('li')`
  display: inline-block;
  float: left;
  margin-bottom: 4px;
  margin-right: 50px;
  h2 {
    padding: 0 0.5rem;
  }
`;

const FullwidthContainer = styled('div')`
  ${theme('elements.fwContainer')};
  background: ${({ theme }) => theme.colors.grey};
  max-height: 220px;
  height: 100%;
`;

const SubMenuWrapper = ({ pose, activeCategory, closeNav, client, data }) => {
  //console.log('data categories menu', activeCategory);
  return (
    <PosedWrapper pose={pose}>
      <InnerWrapper>
        <FullwidthContainer>
          <ContentFit>
            <SubMenuLeftContent pose={pose}>
              <MenuItemsList>
                {activeCategory
                  ? activeCategory.subcategories.map(cat =>
                      cat.subcategories.length > 0 ? (
                        <SubMenuSection
                          key={cat.id}
                          heading={cat}
                          categories={cat.subcategories}
                          onSelect={closeNav}
                        />
                      ) : (
                        <NoThirdTierItems key={cat.id}>
                          <CategoryLink category={cat} onClick={closeNav}>
                            {cat.name}
                          </CategoryLink>
                        </NoThirdTierItems>
                      )
                    )
                  : ''}
              </MenuItemsList>
            </SubMenuLeftContent>
            <SubMenuRightContent pose={pose}>
              <SubMenuRightContentComp
                activeCategory={activeCategory}
                closeNav={closeNav}
              />
            </SubMenuRightContent>
          </ContentFit>
        </FullwidthContainer>
      </InnerWrapper>
    </PosedWrapper>
  );
};

export default SubMenuWrapper;
