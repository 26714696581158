import React from 'react';
import styled from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';
import { ReactComponent as IconPhone } from '../../../svg/Phone.svg';
import { ReactComponent as IconAt } from '../../../svg/At.svg';
// import { ReactComponent as IconMail } from '../../../svg/Mail.svg';
import { ReactComponent as IconMarker } from '../../../svg/Marker.svg';

// {
//   icon: IconMail,
//   info: <a href="mailto:kundtjanst@trofe.se">kundtjanst@trofe.se</a>,
//   id: 3
// },

const contactInformation = [
  {
    key: 'phone',
    icon: IconPhone,
    info: '+46 (0)33 20 54 48',
    id: 1
  },
  {
    key: 'email',
    icon: IconAt,
    info: <a href="mailto:kundtjanst@trofe.se">kundtjanst@trofe.se</a>,
    id: 2
  },
  {
    key: 'address',
    icon: IconMarker,
    info: (
      <p>
        Box 11085
        <br />
        507 11 Borås
      </p>
    ),
    id: 4
  }
];

const TableRow = ({ row }) => (
  <tr>
    <td key={row.icon}>{<row.icon />}</td>
    <td key={row.info}>{row.info}</td>
  </tr>
);

const Table = ({ data }) => {
  return (
    <table>
      <tbody>
        {data.map((row, i) => {
          return <TableRow key={row.id} row={row} />;
        })}
      </tbody>
    </table>
  );
};

const Address = styled('address')`
  td {
    vertical-align: top;
    &:last-of-type {
      padding-left: 1.25rem;
      color: ${theme('colors.greyDark')};
    }
    svg {
      width: 15px;
      height: 15px;
      position: relative;
      top: 3px;
    }
  }
`;

const ContactInfo = ({ contactInfo }) => (
  <Address>
    <Table
      data={[...contactInformation].map(item => ({
        icon: item.icon,
        info: contactInfo[item.key],
        id: item.id
      }))}
    />
  </Address>
);

export default ContactInfo;
