import styled from 'react-emotion';

export default styled('main')`
  flex: 1 1 auto;
  position: relative;
  margin-top: ${({ theme }) => theme.sizes.headerHeight};
  padding-top: ${({ theme }) => theme.sizes.gap};
  //z-index: 1;
  ${({ theme }) => theme.below.md} {
    margin-top: ${({ theme }) => theme.sizes.mobileHeaderHeight};
    padding-bottom: 0;
  }
  ${({ theme }) => theme.above.md} {
    padding-top: 2.5rem;
  }
`;
