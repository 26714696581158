import React, { Component } from 'react';
//import theme from '@jetshop/ui/utils/theme';
import styled from 'react-emotion';

import t from '@jetshop/intl';
import { Link } from 'react-router-dom';
import MobileCategories from './Categories/MobileCategories';
import Transition from 'react-transition-group/Transition';
import RetailerDropdown from './RetailerDropdown';
import AutocompleteQuery from './AutocompleteQuery.gql';

//import { SearchField } from '@jetshop/ui/Search';
import StyledSearchField from './StyledSearchField';

class MobileMenuContent extends Component {
  state = {
    openChild: false,
    searchString: null,
    openMenus: []
    //mobileMenuOpen: this.props.mobileMenuOpen
  };

  toggleChildren = i => {
    let openedChildren = this.state.openMenus;
    //openedChildren = openedChildren.push(i);
    let newArray = [];
    if (openedChildren.includes(i)) {
      //newArray = openedChildren.filter(function(e) { return e !== i })
      for (var key in openedChildren) {
        if (key === i) {
          openedChildren.splice(key, 1);
        }
      }
      newArray = openedChildren;
    } else {
      newArray.push(i);
    }
    this.setState({
      openChild: !this.state.openChild,
      openMenus: newArray
    });
  };

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.props.closeMenu();
    }
  };

  render() {
    const data = this.props.data;
    const closeMenu = this.props.closeMenu;

    const MobileMenuWrapper = styled('div')`
    background: ${({ theme }) => theme.colors.grey};

    a {
      position: relative;
      padding: .5rem ${({ theme }) => theme.sizes.gap} .5rem ${({ theme }) =>
      theme.sizes.gap};
      margin: 0;

      &.active {
        background: ${({ theme }) => theme.colors.greyMediumDark};
        color: ${({ theme }) => theme.colorSecondary};
      }

      &.parentCat {
        background: none;
        color: ${({ theme }) => theme.colors.black};
      }

      & > svg {
        position: absolute;
        top: 16px;
        right: ${({ theme }) => theme.sizes.gap};
      }

      & > div[class*=IconWrap] {
        position: relative;
      }
    }
  }

  &.slide-entering {
    div[class*="ContentSlider"] {
      left: 0px;
    }
  }

  &.slide-entered {
    div[class*="ContentSlider"] {
      left: -100vw;
    }
  }

  &.slide-exiting {
    div[class*="ContentSlider"] {
    left: -100vw;
    }
  }

  &.slide-exited {
    div[class*="ContentSlider"] {
      left: 0px;
    }
  }
  `;

    const MenuWrapper = styled('div')`
      height: 100vh;
      position: relative;
      overflow: hidden;
      a {
        display: block;
      }

      div[class*='ContentSlider'] {
        & > div:first-of-type {
          & > div {
            width: 100%;
            padding: 0 7px 0 5px;
            ${({ theme }) => theme.boxShadow}
            & > input {
              width: 100%;
              background: none;
              padding-top: 20px;
              padding-bottom: 17px;
              margin: 0;
              color: ${({ theme }) => theme.colors.greyDark};
              font-size: 1rem;
              border: none;
              outline: none;
              ${({ theme }) => theme.above.md} {
                min-width: auto;
              }
            }
            //& > div[class*='Flyout'] {
            //  display: none;
            //}
            & > div {
              top: 30px;
            }
            & > button {
              display: none;
              + div {
                top: 50px;
              }
            }
          }
        }
      }
    `;

    const ContentSlider = styled('div')`
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0px;
      transition: all ease-in-out 0.3s;

      input {
        padding-left: 10px !important;
      }

      button {
        margin-right: 15px;
      }

      a[class*='activeCategoryLink'],
      a[class*='css-0'] {
        & + div {
          & > div {
            right: 0;
          }
        }
      }
    `;

    const MenuUl = styled('ul')`
      padding-top: 5px;
    `;

    // const MenuTitle = styled('h1')`
    //   font-weight: bold;
    //   font-size: 16px;
    //   margin: 16px;
    //   position: fixed;
    //   bottom: 40px;
    // `;

    const Divider = styled('hr')`
      border: 1px solid #e8e8e8;
      margin-left: 12px;
      margin-right: 12px;
    `;

    return (
      <Transition in={this.state.openChild} timeout={{ enter: 0, exit: 0 }}>
        {status => (
          <MobileMenuWrapper className={`slide slide-${status}`}>
            <MenuWrapper
              className={'MenuWrapper'}
              onKeyDown={this.handleKeyPress}
            >
              <ContentSlider className={'ContentSlider'}>
                <StyledSearchField
                  placeholder="Vad letar du efter?"
                  autocompleteQuery={AutocompleteQuery}
                  onCancel={() => {}}
                />
                <MenuUl>
                  <MobileCategories
                    categories={data.categories}
                    closeMenu={closeMenu}
                    toggleChildren={this.toggleChildren}
                    openMenus={this.state.openMenus}
                    openChild={this.state.openChild}
                  />
                </MenuUl>
                <div>
                  <Divider />
                </div>
                <Link to="/store-finder">{t('Find Store')}</Link>
                <Link to="/storleksguide-bh-shapingtrosa">{t('Guider')}</Link>
                <RetailerDropdown />
              </ContentSlider>
            </MenuWrapper>
          </MobileMenuWrapper>
        )}
      </Transition>
    );
  }
}

export default MobileMenuContent;
