import React from 'react';
import styled from 'react-emotion';
//import theme from '@jetshop/ui/utils/theme';
// import CategoryLink from '@jetshop/ui/CategoryLink';
import { Link } from 'react-router-dom';
import t from '@jetshop/intl';

const MenuRightContent = styled('div')`
  flex: 1 1 auto;
`;

const MenuColoredBlock = styled('div')`
  display: inline-block;
  min-width: 300px;
  background: ${({ theme }) => theme.colorPrimary};
`;

const MenuColoredBlockInner = styled('div')`
  padding: 2.5rem 3.5rem;
  text-align: center;
  position: relative;
  & > * {
    position: relative;
    z-index: 2;
  }
  &:before {
    content: '';
    position: absolute;
    width: calc(100% - 30px);
    height: calc(100% - 20px);
    left: 15px;
    top: 10px;
    border: 3px solid ${({ theme }) => theme.colors.black};
    z-index: 1;
  }
`;

const SubMenuRightContent = ({ activeCategory, closeNav }) => {
  if (activeCategory) {
    // const cat = activeCategory;
    return (
      <MenuRightContent>
        <MenuColoredBlock>
          <MenuColoredBlockInner>
            <h3>{t('News')}</h3>
            <Link to={'/nyheter'} onClick={closeNav}>
              {t('Shop now')}
            </Link>
          </MenuColoredBlockInner>
        </MenuColoredBlock>
      </MenuRightContent>
    );
  } else {
    return null;
  }
};

export default SubMenuRightContent;
