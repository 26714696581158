import React from 'react';
import styled from 'react-emotion';
import t from '@jetshop/intl';

const DropdownList = styled('ul')`
  display: none;
  min-width: 180px;
  position: absolute;
  top: 100%;
  padding-top: 2px;
  ${({ theme }) => theme.below.md} {
    position: relative;
    padding-top: 0;
    top: 0;
  }
  > li {
    display: block;
    padding: 0.15rem 1.25rem;
    background-color: ${({ theme }) => theme.colors.grey};
    &:first-of-type {
      padding-top: 0.75rem;
      ${({ theme }) => theme.below.md} {
        padding-top: 0;
      }
    }
    &:last-of-type {
      padding-bottom: 0.75rem;
    }
    > a {
      color: ${({ theme }) => theme.colors.black};
      border-bottom: 1px solid transparent;
      transition: border-bottom ease-in-out 0.15s;
      text-decoration: none !important;
      ${({ theme }) => theme.below.md} {
        border-bottom: 0;
      }
      &:hover {
        color: ${({ theme }) => theme.colors.black};
        border-bottom: 1px solid ${({ theme }) => theme.colors.black};
      }
    }
  }
`;

const DropdownContainer = styled('div')`
  position: relative;
  cursor: pointer;
  > span {
    opacity: 0.7;
    font-size: 14px;
    color: #fff;
    font-weight: normal;

    ${({ theme }) => theme.below.md} {
      display: inline-block;
      position: relative;
      padding: .25rem 15px .5rem 15px;
      margin: .25rem 0 0 0;
      font-size: 1rem;
      opacity: 1;
      color: ${({ theme }) => theme.colors.black};
    }

}

  }
  &:hover {
    > ul {
      display: block;
    }
  }
`;

export default () => {
  return (
    <DropdownContainer>
      <span>{t('Återförsäljare Login')}</span>
      <DropdownList>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://order-se.trofe.se"
          >
            {t('Sweden')}
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://order-no.trofe.se"
          >
            {t('Norway')}
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://order-se.trofe.se"
          >
            Other countries
          </a>
        </li>
      </DropdownList>
    </DropdownContainer>
  );
};
