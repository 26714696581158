import Analytics from '@jetshop/core/analytics/Analytics';
import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckout } from '@jetshop/core/analytics/tracking';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import t from '@jetshop/intl';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import { FlyoutTarget } from '@jetshop/ui/Modal/Flyout';
import { Price } from '../Price';
import theme from '@jetshop/ui/utils/theme';
import get from 'lodash.get';
import React, { Fragment } from 'react';
import styled from 'react-emotion';
import Button from '../ui/Button';
import CartItem from './CartItem';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import CartCounter from '../Theme/CartCounter';
import cartQuery from './CartQuery.gql';

import { ReactComponent as Close } from '../../svg/Close.svg';
import { Below } from '@jetshop/ui/Breakpoints';
import FreeShipping from './FreeShipping';

const Flyout = styled('div')`
  height: 100%;
  width: 100%;
  z-index: 999;
  right: 0;
  top: 0;
  background: white;
  color: ${theme('colors.black')};
`;

const Header = styled('header')`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${({ theme }) => theme.space[3]};
  height: 75px;
  h2 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.8rem;
    font-weight: 600;
    font-family: ${theme('fontFamilies.heavy')};
    margin: 0;
    ${theme('below.md')} {
      font-size: 1.3rem;
    }
  }
  border-bottom: 1px solid #e8e8e8;
`;

const CloseButton = styled('button')`
  padding: 0;
  background: transparent;
  border: 0;
  outline: none;
  svg {
    height: 16px;
    width: 16px;
  }
`;

const LightText = styled('span')`
  color: #828282;
  font-size: 0.875rem;
  font-family: ${theme('fontFamilies.body')};
  font-weight: normal;
`;

const CartItems = styled('section')`
  height: calc(100% - 251px);
  overflow-y: auto;
  padding: ${({ theme }) => theme.space[2]};
`;

const Summary = styled('section')`
  position: absolute;
  bottom: 0;
  width: 100%;
  flex: 0 1 auto;
  background: ${theme('colors.greyLightest')};

  a {
    width: 100%;
    color: white;
    :hover {
      color: white;
    }
  }
`;

const SummaryInner = styled('div')`
  padding: 1rem 2rem;
`;

const DeliverySummary = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  padding: 0 $gap;
`;

const TotalSummary = styled('div')`
  font-size: 1.25rem;
  font-weight: 600;
  font-family: ${theme('fontFamilies.heavy')};
  color: ${theme('colors.black')};
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const ButtonRow = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const FlyoutButtonStyle = `
display: block
flex: 0 1 50%;
width: 50% !important;
height: 64px;
text-align: center;
outline: 0;
`;

const ContinueShoppingButton = styled(Button)`
  ${FlyoutButtonStyle}
  background: ${theme('colors.greyMediumDark')};
  color: ${theme('colors.black')};
`;

const Checkout = styled(Button)`
  ${FlyoutButtonStyle}
  background: ${theme('colors.black')};
  color: #fff;
`;

const CartFlyoutView = ({ cart, modal, ...rest }) => {
  const items = get(cart, 'data.cart.items', []);
  const itemsInCart = get(cart, 'data.cart.totalQuantity', 0);
  const checkoutUrl = get(cart, 'data.cart.externalCheckoutUrl');
  const track = useTracker();

  return (
    <Flyout {...rest}>
      <Header>
        <DrawerTrigger id="cart-drawer">
          {drawer => (
            <CloseButton
              onClick={drawer.isOpen ? drawer.hideTarget : drawer.showTarget}
            >
              <Close />
            </CloseButton>
          )}
        </DrawerTrigger>

        <h2>{t('Your cart')}</h2>
        <CartCounter counter={itemsInCart} />
      </Header>

      <CartItems>
        {items.map(item => (
          <CartItem item={item} key={item.id} />
        ))}
      </CartItems>

      <Summary>
        <SummaryInner>
          <Below breakpoint="md">
            {matches =>
              matches
                ? cart &&
                  cart.data && (
                    <FreeShipping
                      className="shipping"
                      cartTotal={cart.data.cart.productTotal}
                    />
                  )
                : null
            }
          </Below>
          <DeliverySummary>
            <LightText>{t('Delivery')}</LightText>
            {/*<LightText>{t('Free for your order')}</LightText>*/}
          </DeliverySummary>
          <TotalSummary>
            <label>{t('Total')}</label>
            <Price price={cart.data.cart.productTotal} />
          </TotalSummary>
        </SummaryInner>
        <ButtonRow>
          <DrawerTrigger id="cart-drawer">
            {flyout => (
              <ContinueShoppingButton
                onClick={flyout.isOpen ? flyout.hideTarget : flyout.showTarget}
              >
                {t('Back to shop')}
              </ContinueShoppingButton>
            )}
          </DrawerTrigger>
          {checkoutUrl && (
            <Checkout
              anchor={true}
              href={checkoutUrl}
              onClick={() => {
                track(trackCartCheckout({ cart: cart.data.cart }));
              }}
            >
              {t('Check out')}
            </Checkout>
          )}
        </ButtonRow>
      </Summary>
    </Flyout>
  );
};

const CartFlyout = props => (
  // Wrap the flyout with the needed providers
  <CartProvider query={cartQuery}>
    {cart =>
      cart && cart.data && cart.data.cart ? (
        <Fragment>
          {/* Flyout is used for desktop */}
          <FlyoutTarget id="cart-drawer">
            {flyout => <CartFlyoutView modal={flyout} cart={cart} {...props} />}
          </FlyoutTarget>

          {/* Drawer is used for mobile */}
          <DrawerTarget id="cart-drawer">
            {drawer => (
              <Drawer size="515" isOpen={drawer.isOpen} right>
                <CartFlyoutView modal={drawer} cart={cart} {...props} />
              </Drawer>
            )}
          </DrawerTarget>
        </Fragment>
      ) : null
    }
  </CartProvider>
);

export default CartFlyout;
