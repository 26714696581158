import React from 'react';
import styled from 'react-emotion';
import SubscribeToNewsletter from '@jetshop/core/components/Mutation/SubscribeToNewsletter';
import t, { Intl } from '@jetshop/intl';
import theme from '@jetshop/ui/utils/theme';

const Wrapper = styled('div')`
  position: relative;
  width: 100%;
  max-width: 31rem;
  margin: 0 auto;

  ${({ theme }) => theme.above.md} {
    margin-bottom: 0;
  }

  form,
  input {
    height: 100%;
    width: 100%;
  }

  input {
    background: #fff;
    align-items: center;
    font-size: 1.1rem;
    padding: 0.75rem 4rem 0.75rem 1rem;
    color: ${theme('colors.greyDark')};
    border: 0;
    outline: 0;
  }
  button {
    cursor: pointer;
    height: 100%;
    margin-top: 1rem;
    color: #fff;
    background: none;
    padding: 0.7rem 1.7rem;
    border: 1px solid #fff;
    outline: none;
    text-align: right;
    opacity: 1 !important;
  }
`;

const Error = styled('div')`
  margin-bottom: 0.5rem;
  color: #fff;
  text-align: center;
`;

const HeadingIntro = styled('h4')`
  font-size: 1.25rem;
  text-align: center;
  margin-bottom: 1rem;
`;

const ParagraphIntro = styled('p')`
  max-width: 540px;
  margin: 0 auto 1rem auto;
  font-size: 1rem;
  line-height: 1.75rem;
  text-align: center;
`;

const InputWrap = styled('div')`
  max-width: 430px;
  margin: 0 auto;
`;

// Prevent the normal form submission behaviour, then call the
// onSubmit provided by the mutation
const submitEmail = (email, onSubmit) => e => {
  e.preventDefault();
  onSubmit(email);
};

class NewsletterField extends React.Component {
  state = {
    email: '',
    completed: false,
    error: false
  };

  // State change methods
  updateEmail = e =>
    this.setState({ email: e.currentTarget.value, error: false });
  setCompleted = () => this.setState({ completed: true });
  setError = () => this.setState({ error: true });
  unsetError = () => this.setState({ error: false });

  render() {
    return (
      <SubscribeToNewsletter
        onCompleted={this.setCompleted}
        onError={this.setError}
      >
        {(subscribe, result) => (
          <React.Fragment>
            {this.state.error ? (
              <Error>
                {t(
                  'Something went wrong. Please check your email and try again.'
                )}
              </Error>
            ) : (
              <>
                <HeadingIntro>{t('Join the newsletter')}</HeadingIntro>
                <ParagraphIntro>
                  Glöm inte att skriv upp dig på vårt nyhetsbrev för att få
                  nyheter och erbjudanden direkt till din mail.
                </ParagraphIntro>
              </>
            )}
            <Wrapper>
              {/* Show the completion success message once signed up, otherwise show the form */}
              {this.state.completed ? (
                <p>{t('You are now subscribed')}</p>
              ) : (
                <form onSubmit={submitEmail(this.state.email, subscribe)}>
                  <InputWrap>
                    <Intl>
                      {/* This is necessary to use t for the placeholder string */}
                      {t => (
                        <input
                          type="email"
                          placeholder={t('E-mail')}
                          onChange={this.updateEmail}
                        />
                      )}
                    </Intl>
                    <button
                      style={this.state.email === '' ? { opacity: 0.5 } : null}
                    >
                      {/* Display 'Submitting…' while the form is posting */}
                      {result.loading ? t('Submitting…') : t('Submit')}
                    </button>
                  </InputWrap>
                </form>
              )}
            </Wrapper>
          </React.Fragment>
        )}
      </SubscribeToNewsletter>
    );
  }
}

export default NewsletterField;
