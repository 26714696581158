import Accordion, { AccordionContent } from '@jetshop/ui/Accordion/Accordion';
import CategoryLink from '@jetshop/ui/CategoryLink';
import React, { Component } from 'react';
import styled, { css } from 'react-emotion';
import getCategoriesByLevel from './__util__/getCategoriesByLevel';
import getCategoriesUnderParent from './__util__/getCategoriesUnderParent';
import hasChildCategories from './__util__/hasChildCategories';
import { NavLink } from 'react-router-dom';

//import Transition from 'react-transition-group/Transition';

import { ReactComponent as ArrowIcon } from '../../../../svg/Arrow.svg';

const activeCategoryLink = css``;

const handleClickAndPreventDefault = (e, handleClick, i) => {
  e.preventDefault();
  handleClick(i);
};

const SubCategoryWrap = styled('div')`
  height: 100%;
  background: #F4F4F4;
  width: 100%;
  position: absolute;
  top: 0;
  right: -100vw;
  transition: all ease-in-out .3s;
  z-index: 1;
  &.slide-show {
    right: 0 !important;
  }
  &.slide-hide {
    right: -100vw !important;
  }
  }
`;

// const ListItem = styled('li')`
//   &.show-child {
//     //& > div[class*='SubCategoryWrap'] {
//     & > div {
//       right: 0 !important;
//       &.slide-entering {
//         right: -100vw;
//         z-index: 2;
//       }
//       &.slide-entered {
//         right: -100vw;
//         z-index: 2;
//       }
//       &.slide-exiting {
//         right: -200vw;
//         z-index: 2;
//       }
//       &.slide-exited {
//         right: -200vw;
//         z-index: 2;
//       }
//     }
//   }
// `;

const BackButton = styled('button')`
  ${({ theme }) => theme.buttons.shared}
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  background: none;
  padding: 0 ${({ theme }) => theme.sizes.gap};
  border: none;
  svg {
    transform: rotate(180deg);
  }
`;

const MenuIconBack = styled('span')``;

const MenuSpanBack = styled('span')`
  margin-left: 1.5rem;
  text-align: left;
  color: ${({ theme }) => theme.colorSecondary};
`;

const ShowAllSpan = styled('span')`
  display: block;
  position: relative;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.greyMediumDark};
  font-weight: bold;
  & > svg {
    position: absolute;
    right: 0;
    top: 0px;
  }
`;

const MobileCategories = ({ categories, closeMenu, parentCategory = null }) => {
  // This component is called recursively. On the first run through, we set up all categories
  // and their subcategories. On subsequent run-throughs (with parentCategory set), we set up
  // the accordion for just that parent Category and its children.
  const categoryLevel = parentCategory
    ? [parentCategory]
    : getCategoriesByLevel(categories, 1);

  return (
    <Accordion single>
      {({ handleClick, openIndexes }) =>
        categoryLevel.map((category, i) => (
          <li key={category.id}>
            {/* // Use AccordionContent */}
            <CategoryLink
              className={openIndexes.includes(i) ? activeCategoryLink : ''}
              onClick={e =>
                // If this category has child categories, we want to open them up with `handleClick`
                // Otherwise, we want to follow the link and `closeMenu`
                hasChildCategories(categories, category)
                  ? handleClickAndPreventDefault(e, handleClick, i)
                  : closeMenu()
              }
              category={category}
            >
              {category.name}
              {category.subcategories.length ? <ArrowIcon /> : ''}
            </CategoryLink>

            <AccordionContent isOpen={openIndexes.includes(i)}>
              <SubCategories
                categories={categories}
                category={category}
                closeMenu={closeMenu}
                handleClick={handleClick}
              />
            </AccordionContent>
          </li>
        ))
      }
    </Accordion>
  );
};

class SubCategories extends Component {
  render() {
    //let { parentCat, categories, category, closeMenu, toggleChildren, openChild } = this.props;
    let { categories, category, closeMenu, handleClick } = this.props;

    return (
      <SubCategoryWrap className={'SubCategoryWrap'}>
        <BackButton onClick={handleClick}>
          <MenuIconBack>
            <ArrowIcon />
          </MenuIconBack>
          <MenuSpanBack>{category.name}</MenuSpanBack>
        </BackButton>
        <NavLink
          to={category.primaryRoute.path}
          className={'parentCat'}
          onClick={closeMenu}
          key={category.name}
        >
          <ShowAllSpan onClick={closeMenu}>
            Visa alla {category.name}
            <ArrowIcon />
          </ShowAllSpan>
        </NavLink>
        {getCategoriesUnderParent(category.subcategories, category.id).map(
          (childCat, i) =>
            // Recursively load deeper category levels
            hasChildCategories(categories, childCat) ? (
              <MobileCategories
                key={i}
                categories={categories}
                closeMenu={closeMenu}
                parentCategory={childCat}
              />
            ) : (
              // Or just display the category link
              <CategoryLink onClick={closeMenu} category={childCat} key={i}>
                {childCat.name}
              </CategoryLink>
            )
        )}
      </SubCategoryWrap>
    );
  }
}

export default MobileCategories;
