import React from 'react';
import styled from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import WishlistCounter from '../Theme/WishlistCounter';

const Button = styled('button')`
  display: flex;
  padding: 0;
  background: transparent;
  border: 0;
  outline: none;
  margin: 0 ${({ theme }) => theme.space[3]};
  width: 28px;

  ${theme('below.md')} {
    margin: 0 ${({ theme }) => theme.space[1]};
  }

  span {
    display: block;
    margin: 0;
    &:first-child {
      font-size: 24px;
    }
    &:last-child {
      font-size: 14px;
    }
  }
`;

const FavoritesButton = () => (
  <DrawerTrigger id="favorites-drawer">
    {drawer => (
      <Button onClick={drawer.isOpen ? drawer.hideTarget : drawer.showTarget}>
        <WishlistCounter />
      </Button>
    )}
  </DrawerTrigger>
);

export default FavoritesButton;
