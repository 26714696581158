import React from 'react';
import { Link } from 'react-router-dom';
import t from '@jetshop/intl';
import theme from '@jetshop/ui/utils/theme';
import styled, { css } from 'react-emotion';
import RetailerDropdown from './RetailerDropdown';
import { ReactComponent as CheckerIconSvg } from '../../../svg/Check.svg';
import { Query } from 'react-apollo';
import topBarQuery from './TopBarQuery.gql';

const TopBarContainer = styled('div')`
  border-bottom: 1px solid ${theme('colors.greyDark')};
  background: ${theme('colors.black')};
  position: relative;
  z-index: 2;
  ${theme('below.sm')} {
    top: ${({ theme }) => theme.sizes.mobileHeaderHeight};
  }

  ${theme('overlay')};
`;

const ThemeContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${({ theme }) => theme.sizes.topBarHeight};
  ${({ theme }) => theme.elements.container};
  ${theme('below.sm')} {
    padding: 0 5px;
  }
`;

const UspContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  ${theme('below.sm')} {
    width: 100%;
  }
`;

const SingleUsp = styled('div')`
  display: flex;
  align-items: center;
  margin: 0 1rem;
  font-size: 1.1vw;
  font-weight: normal;
  color: #fff;
  transition: 0.2s;

  ${theme('above.lg')} {
    font-size: 14px;
  }

  ${theme('below.sm')} {
    font-size: 2.4vw;
    margin: 0 0.25rem;
    line-height: 1.1;
  }
`;

const CheckerIcon = styled(CheckerIconSvg)`
  fill: #fff;
  width: 1.2vw;
  height: 1.2vw;
  margin-right: 0.5rem;

  ${theme('above.lg')} {
    width: 0.9rem;
    height: 0.9rem;
  }

  ${theme('below.sm')} {
    width: 0.5rem;
    height: 0.5rem;
    margin-right: 0.4rem;
  }
`;

const TopNavBlock = styled('div')`
  display: inline-block;
  ${theme('below.sm')} {
    display: none;
  }
  a {
    opacity: 0.7;
    font-size: 14px;
    color: #fff;
    font-weight: normal;
    &:hover {
      opacity: 1;
      color: #fff;
    }
  }
  ${props =>
    props.left
      ? css`
          a {
            margin-right: 30px;
          }
        `
      : css`
          a {
            margin-left: 30px;
          }
        `};
`;

const TopBar = () => {
  return (
    <Query query={topBarQuery}>
      {({ data, loading, error }) => {
        if (loading) return null;
        if (error) {
          console.log(error);
          return null;
        }
        if (!data.category) return null;

        const result = JSON.parse(
          data.category.content.replace(/(<([^>]+)>)/gi, '')
        );
        return (
          <TopBarContainer className={'TopBarContainer'}>
            <ThemeContainer>
              <TopNavBlock left>
                <RetailerDropdown />
              </TopNavBlock>
              <UspContainer>
                {result &&
                  result.usps &&
                  result.usps.map(usp => (
                    <SingleUsp key={usp}>
                      <CheckerIcon />
                      {usp}
                    </SingleUsp>
                  ))}
              </UspContainer>
              <TopNavBlock>
                <Link to="/store-finder">{t('Hitta Butik')}</Link>
                <Link to="/storleksguide-bh-shapingtrosa">{t('Guider')}</Link>
              </TopNavBlock>
            </ThemeContainer>
          </TopBarContainer>
        );
      }}
    </Query>
  );
};

export default TopBar;
