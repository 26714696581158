import React, { Component } from 'react';
import styled from 'react-emotion';
import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';
import Image from '@jetshop/ui/Image';
import t from '@jetshop/intl';
import MaxWidth from '../../Layout/MaxWidth';
import { ReactComponent as ArrowSVG } from '../../../svg/Arrow.svg';
import Helmet from 'react-helmet-async';
import SingleArticleQuery from './SingleArticleQuery.gql';
import CategoriesLevel2 from './CategoriesLevel2.gql';

const ArrowIcon = styled(ArrowSVG)``;

const Container = styled(MaxWidth)`
  max-width: 818px;
  position: relative;
  margin: 0 auto;
  padding-bottom: 2rem;
  //padding-top: 2rem;
  ${({ theme }) => theme.above.sm} {
    //padding-top: 2rem;
  }
`;

const ArticleBanner = styled('div')`
  max-height: 510px;
  overflow: hidden;
  margin-bottom: 28px;
`;

const ArticleContent = styled('div')`
  max-width: 606px;
  margin: 0 auto;

  .alignleft {
    float: left;
    margin: 5px 20px 20px 0;
  }
  .aligncenter {
    display: block;
    margin: 5px auto;
  }
  img {
    max-width: 100%;
    height: auto;
  }
`;

const FloatingRow = styled('div')`
  position: absolute;
  top: 0;
  ${({ theme }) => theme.elements.fwContainer};
  @media (max-width: 1000px) {
    display: none;
  }
`;

const FloatingRowInner = styled('div')`
  ${({ theme }) => theme.elements.container};
  padding: 0;
`;

const FloatingBackLink = styled(Link)`
  color: ${({ theme }) => theme.colors.black};
  ${ArrowIcon} {
    transform: rotate(180deg);
    margin-right: 1rem;
  }
`;

const BackButton = styled(Link)`
  display: block;
  min-width: 290px;
  padding: 8px 0;
  margin: ${({ theme }) => parseInt(theme.sizes.gap) * 3}px auto 0 auto;
  border: 1px solid ${({ theme }) => theme.colors.black};
  text-align: center;
`;

class SingleArticle extends Component {
  render() {
    const routecrumbFragment = gql`
      fragment Routecrumb on Route {
        id
        slug
        path
      }
    `;

    const segment = this.props.location.pathname.split('/').pop();

    return (
      <Query query={CategoriesLevel2}>
        {({ data, loading }) => {
          if (loading) return null;
          const id = data.categories.filter(
            cat => cat.primaryRoute.slug === segment
          )[0].id;
          return data ? (
            <Query query={SingleArticleQuery} variables={{ id }}>
              {({ data, loading }) => {
                if (loading) return null;
                return (
                  <Container>
                    {data.category &&
                      data.category.head &&
                      data.category.head.title && (
                        <Helmet>
                          <title>{data.category.head.title}</title>
                        </Helmet>
                      )}
                    <FloatingRow>
                      <FloatingRowInner>
                        <FloatingBackLink
                          to={`/${
                            data.category.primaryRoute.path.split('/')[1]
                          }`}
                        >
                          <ArrowIcon />
                          {t('Back')}
                        </FloatingBackLink>
                      </FloatingRowInner>
                    </FloatingRow>

                    {data.category.images[0] && (
                      <ArticleBanner>
                        {/* .replace("thumbs", "original") */}
                        <Image
                          src={data.category.images[0].url.replace(
                            'thumbs',
                            'original'
                          )}
                          cover={true}
                          lqip={true}
                          aspect="4:3"
                          alt={data.category.name}
                        />
                      </ArticleBanner>
                    )}
                    {data.category.content && (
                      <ArticleContent>
                        {data.category.name && <h1>{data.category.name}</h1>}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: data.category.content
                          }}
                        />
                      </ArticleContent>
                    )}
                    <BackButton
                      to={`/${data.category.primaryRoute.path.split('/')[1]}`}
                    >
                      {t('Back to all posts')}
                    </BackButton>
                  </Container>
                );
              }}
            </Query>
          ) : null;
        }}
      </Query>
    );
  }
}

export default SingleArticle;
