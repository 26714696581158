import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'emotion-theming';
import { injectGlobal } from 'react-emotion';
import { baseTheme } from '@jetshop/ui/BaseTheme';

const colors = {
  ...baseTheme.colors,
  black: '#262626',
  blue: '#2F80ED',
  green: '#1DAA54',
  greyLightest: '#FAFAFA',
  greyLight: '#F8F8F8',
  greyMediumLight: '#EFEFEF',
  grey: '#F4F4F4',
  greyMediumDark: '#DEDEDE',
  greyDark: '#3E3E3E',
  pink: '#F5D0CE',
  pinkDark: '#AB0A3D',
  red: '#E34040'
};

const colorPrimary = colors.pink;
const colorSecondary = colors.pinkDark;

const fonts = {
  serif: `'Playfair Display', serif`,
  sansSerif: `'Poppins', sans-serif`
};

const fontHeading = fonts.serif;

const typography = {
  h2: `
  font-size: 2.65rem;
  margin-bottom: 2rem;
  `,
  h3: `
  font-size: 1.65rem;
  margin-bottom: 1rem;
  `
};

const sizes = {
  headerHeight: '138px',
  mobileHeaderHeight: '62px',
  mobileCloseGap: '55px',
  topBarHeight: '30px',
  containerSize: '1240px',
  gap: '15px',
  prodElementHeight: '48px'
};

const buttons = {
  shared: `
  outline: 0;
  `,
  headerButton: `
  height: 42px;
  min-width: 154px;
  padding: 0px 22px;
  font-size: 14px;
  `
};

const elements = {
  container: `
  position: relative;
  max-width: ${sizes.containerSize};
  margin: 0 auto;
  padding: 0 ${sizes.gap};
  `,
  fwContainer: `
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  `
};

const effects = {
  easeButton: `
    transition: ease-in-out all .2s;
  `
};

const boxShadow = `
-webkit-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.15);
-moz-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.15);
box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.15);
`;

injectGlobal`
  html, body {
    font-family: ${fonts.sansSerif};
    line-height: 1.75rem;
  }
  /* apply a natural box layout model to all elements, but allowing components to change */
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  p {
    line-height: 1.75rem;
    margin-bottom: 1rem;
  }
  p:last-of-type {
    margin-bottom: 0;
  }
  a {
    font-family: ${fonts.sansSerif};
    text-decoration: none;
    color: ${colors.black};
    outline: none;
  }
  h1,
  h2,
  h3 {
    font-family: ${fontHeading};
    margin-bottom: 1rem;
  }
  h1 {
    font-size: 2.625rem;
    line-height: 1.3;
  }
  h2 {
    font-size: 2.25rem;
    line-height: 1.4;
  }
  h3 {
    font-size: 1.75rem;
    line-height: 1.4;
  }
  h4 {
    font-size: 1.25rem;
  }
`;

const overlay = `
&:after {
  body[class*="preventBodyScrollStyle"] &, body[class*="css-i6bazn "] & {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background: rgba(0,0,0,0.2);
    pointer-events: none;
  }
}
`;

export const theme = {
  ...baseTheme,
  boxShadow,
  buttons,
  colors,
  colorPrimary,
  colorSecondary,
  effects,
  elements,
  fonts,
  fontHeading,
  overlay,
  typography,
  sizes
};

const Theme = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

Theme.propTypes = {
  children: PropTypes.node
};
export default Theme;
