import React from 'react';
import styled from 'react-emotion';

import t from '@jetshop/intl';

import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import theme from '@jetshop/ui/utils/theme';

import FavoriteItem from './FavoriteItem';
import { FavoriteListConsumer } from './FavoriteListProvider';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';

import { ReactComponent as Close } from '../../svg/Close.svg';
import WishlistCounter from '../Theme/WishlistCounter';

const Flyout = styled('div')`
  height: 100%;
  width: 100%;
  z-index: 999;
  right: 0;
  top: 0;
  background: white;
  color: ${theme('colors.black')};
`;

const Header = styled('header')`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${({ theme }) => theme.space[3]};
  height: 75px;
  h2 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.8rem;
    font-weight: 600;
    font-family: ${theme('fontFamilies.heavy')};
    margin: 0;
  }
  border-bottom: 1px solid #e8e8e8;
`;

const ScrollWrapper = styled('div')`
  height: calc(100% - 75px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

const FavoriteItemList = styled('div')`
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
`;

const CloseButton = styled('button')`
  padding: 0;
  background: transparent;
  border: 0;
  outline: none;

  svg {
    height: 16px;
    width: 16px;
  }
`;

const FavoritesFlyoutView = ({ modal, ...rest }) => {
  return (
    <FavoriteListConsumer>
      {({ favoriteList, removeFromList }) => (
        <Flyout {...rest}>
          <Header>
            <DrawerTrigger id="favorites-drawer">
              {drawer => (
                <CloseButton
                  onClick={
                    drawer.isOpen ? drawer.hideTarget : drawer.showTarget
                  }
                >
                  <Close />
                </CloseButton>
              )}
            </DrawerTrigger>

            <h2>{t('Favorites')}</h2>

            <WishlistCounter />
          </Header>

          <ScrollWrapper>
            <FavoriteItemList>
              <DrawerTrigger id="favorites-drawer">
                {drawer =>
                  favoriteList &&
                  favoriteList.map((item, index) => (
                    <FavoriteItem
                      key={index}
                      item={item}
                      removeFromList={removeFromList}
                      drawer={drawer}
                    />
                  ))
                }
              </DrawerTrigger>
            </FavoriteItemList>
          </ScrollWrapper>
        </Flyout>
      )}
    </FavoriteListConsumer>
  );
};

const FavoritesFlyout = props => (
  <DrawerTarget id="favorites-drawer">
    {drawer => (
      <Drawer size="515" isOpen={drawer.isOpen} right>
        <FavoritesFlyoutView modal={drawer} {...props} />
      </Drawer>
    )}
  </DrawerTarget>
);

export default FavoritesFlyout;
