import React, { useContext } from 'react';
import ChannelContext from '@jetshop/core/components/ChannelContext';

import { Price as PriceUI } from '@jetshop/ui/Price';

const CUSTOM_FORMATTER_CHANNELS = [2];

export function useFormattedPrice() {
  const { selectedChannel } = useContext(ChannelContext);

  const shouldFormat = CUSTOM_FORMATTER_CHANNELS.includes(selectedChannel.id);

  const formatter = (price, code) => `${price} ${code}`;

  return shouldFormat ? formatter : undefined;
}

export function Price(props) {
  const formatter = useFormattedPrice();
  return <PriceUI formatter={formatter} {...props} />;
}
