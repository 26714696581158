import React from 'react';
import { withTheme } from 'emotion-theming';

class ModalStyles extends React.Component {
  render() {
    const { theme } = this.props;

    const ModalStyles = Props => (
      <style
        dangerouslySetInnerHTML={{
          __html: `
      .ReactModal__Overlay {
        position: relative;
        z-index: 10;
        background-color: rgba(0, 0, 0, 0.5) !important
      }

      .ReactModal__Overlay > .ReactModal__Content {
        position: relative;
        width: 100vw;
        padding-left: ${theme.sizes.mobileCloseGap};
        left: calc(-100vw - ${theme.sizes.mobileCloseGap}) !important;
        transition: left .2s ease-in-out;
        background-color: #fff;
        overflow: visible;
        outline: 0;
      }

      .ReactModal__Overlay--after-open > .ReactModal__Content {
        left: -${theme.sizes.mobileCloseGap} !important;
      }

      .ReactModal__Overlay--before-close > .ReactModal__Content {
        left: calc(-100vw - ${theme.sizes.mobileCloseGap}) !important;
      }
    `
        }}
      />
    );

    return <ModalStyles />;
  }
}

export default withTheme(ModalStyles);
