//import React, { Component } from 'react';
// import { SearchField } from '@jetshop/ui/Search';
import SearchField from '../../Theme/JetshopUI/SearchField';
import theme from '@jetshop/ui/utils/theme';
import styled from 'react-emotion';
import SearchCloseSVG from '../../../svg/SearchClose.svg';

const StyledSearchField = styled(SearchField)`
  ${SearchField.Wrapper} {
    display: flex;
    width: auto;
    height: 2.5rem;
    position: relative;
    margin: 3px 0;
    color: ${({ theme }) => theme.colors.black};
    &:hover > button {
      display: block !important;
    }
    ${theme('below.sm')} {
      width: calc(100% - 1rem);
      height: 3rem;
      padding-right: 2rem;
    }
    input {
      border: 0;
      background: ${theme('colors.grey')};
      height: 100%;
      min-height: 42px;
      min-width: 288px;
      flex: 1 1 auto;
      font-size: 14px;
      padding: 0 1rem 0 4rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      outline: none;
      --webkit-appearance: none;
    }
  }
  ${SearchField.Cancel} {
    display: none;
    position: absolute;
    top: 11px;
    right: 15px;
    left: auto;
    content: '';
    width: 19px;
    height: 19px;
    position: absolute;
    background: center / cover no-repeat url(${SearchCloseSVG});
    color: transparent;
    outline: none;
    overflow: hidden;
    ${theme('below.sm')} {
      left: auto;
      right: 0;
      top: 1rem;
    }
  }

  ${SearchField.Flyout} {
    text-align: left;
    top: 40px;
    ${theme('below.sm')} {
      top: 4rem;
    }
    // > * {
    //   padding: 0.5rem 0;
    // }
    h2 {
      display:none;
    }
    li {
      padding: 0.5rem;
      transition: background ease-in-out .2s;
      &:nth-child(even) {
        //background: ${({ theme }) => theme.colors.grey};
        background: #fff;
      }
      &:nth-child(odd) {
        background: ${({ theme }) => theme.colors.greyLightest};
      }
      &:hover {
        background: ${({ theme }) => theme.colors.greyMediumDark};
      }
    }
    a {
      display: block;
      color: ${({ theme }) => theme.colors.black};
    }
  }
`;

export default StyledSearchField;
