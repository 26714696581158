import throwErrorInDev from '@jetshop/core/helpers/throwErrorInDev';
import t from '@jetshop/intl';
import { FlyoutTarget, FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import SuggestedTerm from '@jetshop/ui/Search/SuggestedTerm';
import * as React from 'react';
import styled, { css } from 'react-emotion';
import SearchAutoCompleteContainer from '@jetshop/ui/Search/SearchAutoCompleteContainer';

const Wrapper = styled('div')`
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
`;

const SearchFieldWrapper = styled('div')`
  width: 12rem;
  position: relative;
  font-size: 16px;
  font-weight: normal;
`;

const Flyout = styled('div')`
  position: absolute;
  background: white;
  z-index: 999;
  border: 1px solid #e8e8e8;
  width: 100%;
  top: 2rem;
  left: 0;
`;

const CancelButton = styled('button')`
  font-size: 0.875rem;
  border: 0;
  background: transparent;
  color: #878787;
  margin-left: 1rem;
`;

const resultStyles = css`
  ul + h2 {
    margin-top: 1em;
  }
`;

const SearchResults = ({ term, result, loading, onClick }) => {
  const products = result?.products;
  const categories = result?.categories;

  if (
    !loading &&
    products?.result.length === 0 &&
    categories?.result.length === 0
  ) {
    return null;
  }

  return (
    <div className={resultStyles}>
      {products?.result.length > 0 && (
        <>
          <h2>{t('Products')}</h2>
          <ul style={loading ? { opacity: 0.5 } : null}>
            {products.result.map(item => (
              <SuggestedTerm
                term={term}
                onClick={onClick}
                key={item.id}
                item={item}
              />
            ))}
          </ul>
        </>
      )}

      {categories?.result.length > 0 && (
        <>
          <h2>{t('Categories')}</h2>
          <ul style={loading ? { opacity: 0.5 } : null}>
            {categories.result.map(item => (
              <SuggestedTerm
                term={term}
                onClick={onClick}
                key={item.id}
                item={item}
              />
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

const SearchField = ({
  initialValue,
  className,
  placeholder = '',
  autocompleteQuery,
  ...rest
}) => {
  throwErrorInDev(
    !autocompleteQuery,
    `You forgot to pass 'autocompleteQuery' to SearchField`
  );

  return (
    <SearchAutoCompleteContainer
      autocompleteQuery={autocompleteQuery}
      initialValue={initialValue}
      focusOnLoad={false}
      {...rest}
    >
      {searchField => (
        <Wrapper className={className} data-flight-searchfield="">
          <SearchFieldWrapper>
            <FlyoutTrigger showCover={false} id="searchFlyout">
              {({ showTarget, hideTarget }) => (
                <React.Fragment>
                  <input
                    placeholder={placeholder}
                    {...searchField.getInputProps({
                      onFocus: showTarget
                    })}
                    data-flight-search-autocomplete-field=""
                  />

                  <CancelButton
                    {...searchField.getCancelProps({ onClick: hideTarget })}
                    data-flight-searchfield-cancel
                  >
                    {t('Cancel')}
                  </CancelButton>
                </React.Fragment>
              )}
            </FlyoutTrigger>

            <FlyoutTarget id="searchFlyout">
              {flyout => {
                const hasResults =
                  searchField.result?.products?.result.length > 0 ||
                  searchField.result?.categories?.result.length > 0;
                const { loading, isDirty, isOpen } = searchField;

                const showFlyout =
                  isDirty && !loading && hasResults && isOpen && flyout.isOpen;

                if (showFlyout) {
                  return (
                    <Flyout data-flight-searchfield-flyout>
                      <div {...searchField.getFlyoutProps()}>
                        {searchField.isDirty && (
                          <React.Fragment>
                            <SearchResults
                              term={searchField.term}
                              result={searchField.result}
                              onClick={flyout.hideTarget}
                              loading={searchField.loading}
                            />
                          </React.Fragment>
                        )}
                      </div>
                    </Flyout>
                  );
                }

                return null;
              }}
            </FlyoutTarget>
          </SearchFieldWrapper>
        </Wrapper>
      )}
    </SearchAutoCompleteContainer>
  );
};

SearchField.Flyout = Flyout;
SearchField.Wrapper = SearchFieldWrapper;
SearchField.Cancel = CancelButton;

export default SearchField;
