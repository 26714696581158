import React, { Fragment } from 'react';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import get from 'lodash.get';
import styled, { css } from 'react-emotion';
import theme from '@jetshop/ui/utils/theme';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import ToastContainerWrapper from './ToastContainerWrapper';
import { ToastContainer } from 'react-toastify';
import FavoritesButton from '../FavoriteList/FavoritesButton';
import CartCounter from '../Theme/CartCounter';
import cartQuery from './CartQuery.gql';

import { Price } from '../Price';
import FreeShipping from './FreeShipping';
import { Below } from '@jetshop/ui/Breakpoints';

const Button = styled('button')`
  padding: 0;
  background: ${theme('colors.grey')};
  color: ${theme('colors.black')};
  border: 0;
  outline: none;
  :hover {
    color: ${theme('colors.black')};
  }
  svg {
    //height: 18px;
  }

  ${theme('above.md')} {
    // span[class*='CartWrapper'] {
    //   width: auto;
    //   span {
    //     position: relative;
    //     top: auto;
    //     right: auto;
    //     //margin-left: ${({ theme }) => theme.space[2]};
    //     margin-left: .25rem;
    //     box-shadow: none;
    //   }
    // }
  }
`;

const CartButtonEle = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${theme('buttons.headerButton')}

  ${theme('below.md')} {
    min-width: auto;
    padding: 0 16px;
  }
`;

const CartPrice = styled('span')`
  color: ${theme('colors.black')};
  font-weight: bold;
  padding-left: 0.5rem;

  ${theme('below.md')} {
    display: none;
  }
`;

const StyledToastContainer = styled(ToastContainer)`
  ${theme('above.sm')} {
    width: unset;
  }
`;

const CartButton = () => {
  return (
    <Fragment>
      <ToastContainerWrapper>
        <FavoritesButton />
        <StyledToastContainer
          toastClassName={css`
            padding: 0px;
            cursor: auto;
            font-family: inherit;
          `}
          autoClose={false}
          hideProgressBar
          closeButton={false}
          closeOnClick={false}
        />
      </ToastContainerWrapper>
      <CartProvider query={cartQuery}>
        {result => {
          // Set items in cart to the API result.
          // If the result is undefined, fall back to 0
          const itemsInCart = get(result, 'data.cart.totalQuantity', 0);
          //const dataCart = get(result, 'data', 0);
          //const cartTotal =  get(result, 'data.cart.productTotal', 100);

          return (
            <>
              <DrawerTrigger preventOverflow={true} id="cart-drawer">
                {drawer => (
                  <Button
                    data-testid="header-cart"
                    onClick={
                      drawer.isOpen ? drawer.hideTarget : drawer.showTarget
                    }
                  >
                    <CartButtonEle>
                      <CartCounter counter={itemsInCart} />
                      <CartPrice>
                        {result && result.data && result.data.cart ? (
                          <Price price={result.data.cart.productTotal} />
                        ) : (
                          '0'
                        )}
                      </CartPrice>
                    </CartButtonEle>
                  </Button>
                )}
              </DrawerTrigger>
              <Below breakpoint="md">
                {matches =>
                  matches
                    ? null
                    : result &&
                      result.data &&
                      result.data.cart && (
                        <FreeShipping
                          className="shipping"
                          cartTotal={result.data.cart.productTotal}
                        />
                      )
                }
              </Below>
            </>
          );
        }}
      </CartProvider>
    </Fragment>
  );
};

export default CartButton;
