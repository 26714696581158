import homeCategoriesQuery from './Categories/HomeCategoriesQuery.gql';
import { Below } from '@jetshop/ui/Breakpoints';
import theme from '@jetshop/ui/utils/theme';
import React, { Component, Fragment } from 'react';
import { Query } from 'react-apollo';
import styled, { css } from 'react-emotion';
import ReactModal from 'react-modal';
import { Link } from 'react-router-dom';
import CartButton from '../../Cart/CartButton';
import CartFlyout from '../../Cart/CartFlyout';
import FavoritesFlyout from '../../FavoriteList/FavoritesFlyout';
import MainNav from './MainNav';
import TopNav from './TopNav';

import TopBar from './TopBar';
import LogotypeImgSrc from '../../../images/logo-black.jpg';

import MobileMenuContent from './MobileMenuContent';

import ModalStyles from './ModalStyles';
import { ReactComponent as IconMenu } from '../../../svg/Menu.svg';

const Container = styled('header')`
  //height: ${({ theme }) => theme.sizes.headerHeigt};
  //position: relative;
  background: #fff;
  //z-index: 2;
  a {
    text-decoration: none;
    color: inherit;
    &:hover {
      text-decoration: underline;
      color: #000;
    }
  }
  ${theme('below.sm')} {
    display: flex;
    flex-direction: column;
    & > div[class*='TopBarContainer'] {
      order: 2;
    }
    & > nav {
      order: 1;
    }
  }
`;

// const Divider = styled('hr')`
//   border: 1px solid #e8e8e8;
//   margin-left: 12px;
//   margin-right: 12px;
// `;

const MobileMenuModal = styled(ReactModal)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  a {
    margin-left: 16px;
    margin-bottom: 16px;
    font-size: 16px;
    color: black;
    text-decoration: none;
  }
`;

// const MenuTitle = styled('h1')`
//   font-weight: bold;
//   font-size: 16px;
//   margin: 16px;
// `;

const MenuButton = styled('button')`
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 12px;
  background: transparent;
  > span {
    margin-top: 5px;
  }
  > svg {
    height: 18px;
  }
`;

// const SearchButton = styled(MenuButton)`
//   align-items: center;
//   svg {
//     fill: ${theme('colors.black')};
//   }
// `;

const LogoAnchor = styled(Link)`
  display: block;
  position: relative;
`;

const LogotypeImg = styled('img')`
  display: block;
  height: 33px;
`;

const MobileNav = css`
  padding: 10px 15px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: #fff;
  -webkit-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  z-index: 3;
  .MenuItemLeft {
    float: left;
  }
  .MenuItemRight {
    float: right;
  }
  /* & > div[class*='Flyout'] {
    display: none;
  } */
`;

const CloseArea = styled('div')`
  width: ${({ theme }) => theme.sizes.mobileCloseGap};
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
`;

const MobileCartWrap = styled('div')`
  display: flex;
  align-items: center;

  & > button {
    position: relative;
    background: none;
    margin-right: 4px;
    & > svg {
      height: 23px;
    }
    & > span {
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: space-around;
      height: 20px;
      width: 20px;
      position: absolute;
      top: -10px;
      right: -10px;
      font-size: 12px;
      background: ${({ theme }) => theme.colors.black};
      border-radius: 50%;
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

class Header extends Component {
  state = {
    activeCategory: null,
    mobileMenuOpen: false,
    searchOpen: false
  };

  setActiveCategory = activeCategory => this.setState({ activeCategory });
  closeMenu = () => this.setState({ mobileMenuOpen: false });

  renderMobileMenu = () => {
    const { mobileMenuOpen } = this.state;
    const { result } = this.props;
    const { data } = result;

    if (data && data.categories) {
      return (
        <MobileMenuModal
          isOpen={mobileMenuOpen}
          onRequestClose={this.closeMenu}
          closeTimeoutMS={250}
        >
          <MobileMenuContent closeMenu={this.closeMenu} data={data} />
          <CloseArea onClick={this.closeMenu} />
        </MobileMenuModal>
      );
    } else return null;
  };

  render() {
    return (
      <Container>
        {/* <Menu result={this.props.result} /> */}
        <TopBar />
        {/* <StoreName> */}
        <>
          {/* this.state.searchOpen && <StyledSearchField onCancel={() => this.setState({ searchOpen: false })} /> */}
        </>
        {/* </StoreName> */}
        <Below breakpoint="md">
          {matches =>
            matches ? (
              <Fragment>
                <ModalStyles />
                <nav className={MobileNav}>
                  {this.state.searchOpen || (
                    <Fragment>
                      <TopNav left>
                        <LogoAnchor to="/">
                          <LogotypeImg src={LogotypeImgSrc} alt="Trofé" />
                        </LogoAnchor>
                      </TopNav>
                      <TopNav right>
                        {/*
                        <SearchButton
                          onClick={() => this.setState({ searchOpen: true })}
                        >
                           <Search />
                          <span>{t('Search')}</span>
                        </SearchButton>
                        */}
                        <MobileCartWrap>
                          <CartButton />
                        </MobileCartWrap>
                        <MenuButton
                          onClick={() =>
                            this.setState({ mobileMenuOpen: true })
                          }
                        >
                          {/* <Menu /> */}
                          <span>
                            <IconMenu />
                          </span>
                        </MenuButton>
                      </TopNav>
                    </Fragment>
                  )}
                </nav>
                {this.renderMobileMenu()}
              </Fragment>
            ) : (
              <MainNav
                setSearchOpen={() => this.setState({ searchOpen: true })}
                searchIsOpen={this.state.searchOpen}
                categories={this.props.result}
              />
            )
          }
        </Below>
        <CartFlyout />
        <FavoritesFlyout />
      </Container>
    );
  }
}

export default props => (
  <Below breakpoint="md">
    {matches =>
      matches ? (
        <Query variables={{ levels: 2 }} query={homeCategoriesQuery}>
          {result => <Header result={result} />}
        </Query>
      ) : (
        <Query variables={{ levels: 1 }} query={homeCategoriesQuery}>
          {result => <Header result={result} />}
        </Query>
      )
    }
  </Below>
);
