import React, { Component } from 'react';
import styled from 'react-emotion';
import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';
import ArticleFeedQuery from './ArticleFeedQuery.gql';
import Image from '@jetshop/ui/Image';
import MaxWidth from '../../Layout/MaxWidth';
import Helmet from 'react-helmet-async';

const ArticleHeight = '220px';

const Container = styled(MaxWidth)`
  //padding-top: 2rem;
  max-width: 1020px;
  margin: 0 auto;
  padding-bottom: 2rem;
  ${({ theme }) => theme.above.sm} {
    //padding-top: 2rem;
  }
`;

const ArticleHeader = styled('div')`
  max-width: 605px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 54px;
  h1 {
    margin-bottom: 1.5rem;
  }
`;

const ArticlePostSingle = styled('div')`
  display: flex;
  margin-bottom: 20px;
  ${({ theme }) => theme.below.sm} {
    display: block;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
  h3 > a {
    font-family: ${({ theme }) => theme.fontHeading};
    color: ${({ theme }) => theme.colors.black};
  }
`;

const ArticlePostImageContainer = styled('div')`
  flex: 1 0 40%;
  padding-right: 50px;
  overflow: hidden;
  ${({ theme }) => theme.below.sm} {
    flex: 1 1 100%;
    padding-right: 0;
    padding-bottom: 1rem;
  }
  & > div {
    height: ${ArticleHeight};
  }
`;

const ArticlePostContent = styled('div')`
  flex: 1 1 auto;
  ${({ theme }) => theme.below.sm} {
    flex: 1 1 100%;
  }
`;

class ArticleFeed extends Component {
  render() {
    const segment = this.props.location.pathname.split('/').pop();
    let id;

    if (segment === 'inspiration') {
      id = 183;
    } else if (segment === 'guider') {
      id = 186;
    }

    return (
      <Query query={ArticleFeedQuery} variables={{ id }}>
        {({ data, loading }) => {
          if (loading) return '';
          return data.category && data.category.subcategories.length ? (
            <Container>
              <ArticleHeader>
                {data.category &&
                  data.category.mainHeader &&
                  data.category.head &&
                  data.category.head.title && (
                    <>
                      <Helmet>
                        <title>{data.category.head.title}</title>
                      </Helmet>
                      <h1>{data.category.name}</h1>
                    </>
                  )}
                {data.category && data.category.content && (
                  <div
                    dangerouslySetInnerHTML={{ __html: data.category.content }}
                  />
                )}
              </ArticleHeader>
              {data.category.subcategories.map((post, i) => (
                <ArticlePostSingle key={i}>
                  {post.images[4] && (
                    <ArticlePostImageContainer>
                      {/* .replace("thumbs", "original") */}
                      <Image
                        src={post.images[4].url}
                        cover
                        aspect="4:3"
                        alt={post.name}
                      />
                    </ArticlePostImageContainer>
                  )}
                  <ArticlePostContent>
                    {/*<h3><Link to={`/${post.primaryRoute.path.split('/')[1]}/${post.primaryRoute.path}`}>{post.name}</Link></h3>*/}
                    <h3>
                      <Link to={post.primaryRoute.path}>{post.name}</Link>
                    </h3>
                    {
                      //console.log("post.content", post.content.match(/<p[^>]*>([^<]+)<\/p>/)[0] )
                      // __html: post.content.trim().substr(0, 280) + '...'
                    }
                    {post.content && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: post.content.match(/<p[^>]*>([^<]+)<\/p>/)[0]
                        }}
                      />
                    )}
                  </ArticlePostContent>
                </ArticlePostSingle>
              ))}
            </Container>
          ) : null;
        }}
      </Query>
    );
  }
}

// const QUERY_BLOG_POSTS = gql`
// query Posts {
//   category(${cat_id}) {
//     subcategories {
//       id
//     }
//   }
// }
// `;
//
// const QUERY_GUIDES = gql`
// query Guides {
//   category(id: ${cat_id}) {
//     subcategories {
//       id
//     }
//   }
// }
// `;
//
// export default compose(
//   graphql(QUERY_BLOG_POSTS, { name: 'QUERY_BLOG_POSTS' }),
//   graphql(QUERY_GUIDES, { name: 'QUERY_GUIDES' })
// )(ArticleFeed);

export default ArticleFeed;
