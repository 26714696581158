import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CartFlyout from '../../Cart/CartFlyout';
import FavoritesFlyout from '../../FavoriteList/FavoritesFlyout';
import MenuContainer from './ThreeTierMenu/MenuContainer';
import TopNav from './TopNav';
import theme from '@jetshop/ui/utils/theme';
import styled from 'react-emotion';
import LogotypeImgSrc from '../../../images/logo-black.jpg';
import { ReactComponent as Search } from '../../../svg/Search.svg';
import ToggleScrollSearch from './ToggleScrollSearch';
import StyledSearchField from './StyledSearchField';
import AutocompleteQuery from './AutocompleteQuery.gql';

import { Intl } from '@jetshop/intl';

const Nav = styled('nav')`
position: absolute;
top: ${theme('sizes.topBarHeight')};
right: 0;
left: 0;
background: #fff;
${theme('boxShadow')};
${theme('overlay')};

& > div:not(.HeaderWrapper) {
  position: fixed !important;
  transition: all ease-in-out .5s;
    & > div[class*="Flyout"] {
      position: absolute;
    }
}

&.scrolled {
  position: fixed;
  top: 0;
  z-index: 9;
  & > div[class*="HeaderWrapper"] {
    & > .MenuItemLeft {
      display: none;
    }
    & > a[class*="LogoAnchor"] {
      position: relative;
      top: 0;
      left: 0;
      right: auto;
      margin 0;
      order: 1;
    }

    & > .MenuItemRight {
      order: 3;
      & > div > button {
        background: none;
      }
      button[class*="Button"] {
        & > div {
          min-width: auto;
          padding: 0;
          & > span[class*="CartPrice"] {
            display: none;
          }
        }
      }
      & > div {
        & > div {
          &:first-of-type {
            display: inline-block;
            & > button {
              width: auto;
              height: auto;
              position: relative;
              top: 4px;
              left: 0;
              padding: 0;
              & > svg {
                width: 1.4rem;
                height: 1.4rem;
              }
            }
          }
        }
      }
    }

    // span[class*="CartWrapper"] {
    //   width: 35px;
    //   & > svg {
    //     width: 27px;
    //     height: 24px;
    //     //margin-right: ${({ theme }) => theme.space[1]};
    //   }
    //   & > span {
    //     position: absolute;
    //     top: -12px;
    //     right: -10px;
    //     -webkit-box-shadow: 0px 0px 14px -4px #000000;
    //     box-shadow: 0px 0px 14px -4px #000000;
    //   }
    // }

    & > div[class*="Wrapper"] {
      flex: 0 1 auto;
      order: 2;
      margin-top: 0;
      & > section {
        position: fixed;
        top: 60px;
      }
    }
  }
}
`;

const LogoAnchor = styled(Link)`
  display: block;
  flex: 0 1 auto;
  max-width: 120px;
  width: 100%;
  position: absolute;
  top: 0px;
  right: 0;
  left: 0;
  margin: 0 auto;
`;

const LogotypeImg = styled('img')`
  display: block;
  height: 43px;
  margin: 0 auto;
`;

const HeaderWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  ${theme('elements.container')}
  margin-top: 20px;
  margin-bottom: 20px;
  & > div[class*='Wrapper'] {
    flex: 1 1 100%;
    margin-top: 0.5rem;
    ${theme('above.md')} {
      margin-top: 2rem;
    }
  }
`;

// const CategoryButtonEle = styled('div')`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   ${theme('buttons.headerButton')}
//   span {
//     padding-right: 2rem;
//     color: #fff;
//     font-weight: normal;
//   }
// `;

const SearchContainer = styled('div')`
  position: relative;
`;

const SearchButton = styled('button')`
  position: absolute;
  left: 1rem;
  top: 0.8rem;
  background: none;
  height: 20px;
  width: 20px;
  padding-right: 2.5rem;
  ${theme('buttons.shared')}
  svg {
    fill: ${theme('colors.black')};
  }
`;
//
// const StyledSearchField = styled(SearchField)`
//   ${SearchField.Wrapper} {
//     display: flex;
//     width: auto;
//     height: 2.5rem;
//     position: relative;
//     margin: 3px 0;
//     color: ${({theme}) => theme.colors.black};
//     ${theme('below.sm')} {
//       width: calc(100% - 1rem);
//       height: 3rem;
//       padding-right: 2rem;
//     }
//     input {
//       border: 0;
//       background: ${theme('colors.grey')};
//       height: 100%;
//       min-height: 42px;
//       min-width: 288px;
//       flex: 1 1 auto;
//       font-size: 14px;
//       padding: 0 1rem 0 4rem;
//       display: flex;
//       align-items: center;
//       justify-content: flex-start;
//       outline: none;
//       &-webkit-appearance: none;
//       &:focus+button {
//         display: block !important;
//       }
//     }
//   }
//   ${SearchField.Cancel} {
//     display: none;
//     position: absolute;
//     top: 11px;
//     right: 15px;
//     left: auto;
//     content: '';
//     width: 19px;
//     height: 19px;
//     position: absolute;
//     background: center / cover no-repeat url(${SearchCloseSVG});
//     color: transparent;
//     outline: none;
//     overflow: hidden;
//     ${theme('below.sm')} {
//       left: auto;
//       right: 0;
//       top: 1rem;
//     }
//   }
//
//   ${SearchField.Flyout} {
//     text-align: left;
//     top: 40px;
//     ${theme('below.sm')} {
//       top: 4rem;
//     }
//     // > * {
//     //   padding: 0.5rem 0;
//     // }
//     h2 {
//       display:none;
//     }
//     li {
//       padding: 0.5rem;
//       transition: background ease-in-out .2s;
//       &:nth-child(even) {
//         //background: ${({theme}) => theme.colors.grey};
//         background: #fff;
//       }
//       &:nth-child(odd) {
//         background: ${({theme}) => theme.colors.greyLightest};
//       }
//       &:hover {
//         background: ${({theme}) => theme.colors.greyMediumDark};
//       }
//     }
//     a {
//       display: block;
//       color: ${({theme}) => theme.colors.black};
//     }
//   }
// `;

//const MainMenu = ({ searchIsOpen, setSearchOpen, categories }) => (
class MainMenu extends Component {
  state = {
    navScrollClass: ''
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.navScrollClass !== this.state.navScrollClass) return true;
    if (this.props.searchIsOpen !== nextProps.searchIsOpen) return true;
    if (this.props.categories.loading !== nextProps.categories.loading)
      return true;
    return false;
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll);
  }

  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    const navScrollClass = winScroll > 108 ? 'scrolled' : '';
    if (this.state.navScrollClass !== navScrollClass) {
      this.setState({
        navScrollClass: navScrollClass
      });
    }
  };

  render() {
    // let categories = this.props.categories;
    // const setSearchOpen = () => this.setState({ searchOpen: true });

    const { setSearchOpen, categories } = this.props;

    return (
      <Nav className={this.state.navScrollClass}>
        <HeaderWrapper className={'HeaderWrapper'}>
          <TopNav left>
            <SearchContainer>
              <Intl>
                {t => (
                  <>
                    <StyledSearchField
                      placeholder={t('Vad letar du efter?')}
                      autocompleteQuery={AutocompleteQuery}
                      onCancel={() => setSearchOpen(false)}
                    />
                    <SearchButton onClick={setSearchOpen}>
                      {<Search />}
                    </SearchButton>
                  </>
                )}
              </Intl>
            </SearchContainer>
            {/*
              <Link to="/">{t('Customer Service')}</Link>
              <ChannelSelector />
              <Link to="/">{t('Find Store')}</Link>
            */}
          </TopNav>

          <LogoAnchor to="/" className={'LogoAnchor'}>
            <LogotypeImg src={LogotypeImgSrc} alt="Trofé" />
          </LogoAnchor>

          <TopNav right>
            <ToggleScrollSearch />
          </TopNav>

          <MenuContainer queryData={categories.data} />
        </HeaderWrapper>

        {/*<CartFlyout className={'Flyout'} />*/}
        {/*<FavoritesFlyout className={'Flyout'} />*/}
      </Nav>
    );
  }
}

export default MainMenu;
