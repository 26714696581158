import React, { Component, Fragment } from 'react';
import styled from 'react-emotion';
import { Price } from '../Price';
import Image from '@jetshop/ui/Image';

import { Link } from 'react-router-dom';

import { ReactComponent as Close } from '../../svg/Close.svg';

const Wrapper = styled('div')`
  display: flex;
  flex: 0 0 calc(33.33% - 12px);
  margin-right: 16px;
  margin-bottom: 16px;
  position: relative;

  &:nth-child(3n + 3) {
    margin-right: 0;
  }

  div,
  a {
    width: 100%;
    text-align: center;
    font-size: 0.85rem;
    line-height: 1.4rem;

    &:hover {
      text-decoration: none;
    }
  }
`;

const ProductDetail = styled('div')`
  margin-top: 8px;
`;

const RemoveIcon = styled('button')`
  z-index: 10;
  cursor: pointer;
  position: absolute;
  top: -10px;
  right: -10px;
  background: black;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  outline: none;

  svg {
    fill: white;
  }
`;

class FavoriteItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showItem: true
    };
  }

  render() {
    const { item, removeFromList, drawer } = this.props;
    let showItem = this.state.showItem;
    return (
      <Fragment>
        {showItem && (
          <Wrapper>
            <Link
              to={item.product.primaryRoute.path}
              onClick={drawer.isOpen ? drawer.hideTarget : drawer.showTarget}
            >
              <Image
                src={
                  (item.product.images[0].sizes &&
                    item.product.images[0].sizes[0].url) ||
                  item.product.images[0].url
                }
                cover
                aspect="3:4"
                sizes={[1 / 5]}
                alt={item.product.name}
              ></Image>
              <RemoveIcon
                onClick={event => {
                  event.preventDefault();
                  removeFromList(item.product.id);
                }}
              >
                <Close />
              </RemoveIcon>

              <ProductDetail>
                <div className="name">{item.product.name}</div>
                <div className="price">
                  <Price price={item.product.price} />
                </div>
              </ProductDetail>
            </Link>
          </Wrapper>
        )}
      </Fragment>
    );
  }
}

export default FavoriteItem;
