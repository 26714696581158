import React, { Component, Fragment } from 'react';
import styled from 'react-emotion';
import { Query } from 'react-apollo';
import { Link } from 'react-router-dom';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import theme from '@jetshop/ui/utils/theme';
import t from '@jetshop/intl';
import StoresSearch from './StoresSearch';
import Pin from './pin.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import storesQuery from './StoresQuery.gql';

const StoreFinderWrapper = styled('div')`
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 0;
  flex-wrap: wrap;
  margin-top: -25px;
`;
const Left = styled('div')`
  width: ${props => (props.narrow ? '100%' : '70%')};
  height: ${props => (props.narrow ? '30vh' : 'calc(100vh - 200px)')};

  ${theme('below.lg')} {
    width: 100%;
    height: 400px;
  }
`;
const Right = styled('div')`
  width: ${props => (props.narrow ? '100%' : '30%')};
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  overflow: hidden;
  height: ${props =>
    props.narrow ? 'calc(100vh - 40vh)' : 'calc(100vh - 200px)'};

  flex-direction: column;

  ${theme('below.lg')} {
    width: 100%;
  }
`;

const MapFrame = styled('div')`
  width: 100%;
  height: 100%;
  margin: auto;
`;

const FullScreen = styled(Link)`
  position: absolute;
  color: #c4c4c4;
  font-size: 16px;
  z-index: 2;
  left: 10px;
  top: 10px;
  text-decoration: none;
  svg {
    font-size: 22px;
  }
`;

const mapStyles = require('./mapStyles.json');

const containerStyle = {
  position: 'relative'
};

export class Stores extends Component {
  state = {
    clickedStorePosition: {},
    center: {},
    zoom: 4,
    clickedId: null,
    showInfo: false,
    showStores: true,
    showOutlets: true
  };

  handleStores = type => {
    if (type === 'stores') {
      this.setState({ showStores: !this.state.showStores });
    } else {
      this.setState({ showOutlets: !this.state.showOutlets });
    }
  };

  clickedStoreBox = e => {
    this.setState({
      clickedStorePosition: {
        lat: e.currentTarget.getAttribute('data-lat'),
        lng: e.currentTarget.getAttribute('data-lng')
      },
      center: {
        lat: e.currentTarget.getAttribute('data-lat'),
        lng: e.currentTarget.getAttribute('data-lng')
      },
      zoom: 16,
      showInfo:
        e.currentTarget.id === this.state.clickedId
          ? this.exitInfoWindor
          : true,
      clickedId: parseInt(e.currentTarget.id)
    });
  };

  exitInfoWindor = () => {
    this.setState({
      showInfo: false
    });
  };

  render() {
    const { narrow, closeFlyout } = this.props;

    return (
      <Query query={storesQuery}>
        {({ data }) => {
          var stores = [];
          if (data && data.stores) {
            stores = data.stores;
          }
          return data && stores ? (
            <Fragment>
              <StoreFinderWrapper>
                <Left narrow={narrow}>
                  {narrow && (
                    <FullScreen onClick={closeFlyout} to="/store-finder">
                      <FontAwesomeIcon icon={['fal', 'expand']} />{' '}
                      {t('Full screen')}
                    </FullScreen>
                  )}
                  <MapFrame>
                    <Map
                      google={this.props.google}
                      initialCenter={{
                        lat: 63.5376013,
                        lng: 7.4941472
                      }}
                      zoom={this.state.zoom}
                      center={this.state.center}
                      styles={mapStyles}
                      containerStyle={containerStyle}
                      mapTypeControl={false}
                      fullscreenControl={false}
                    >
                      {stores.map((store, index) => {
                        var lat = store.coordinates.latitude;
                        var lng = store.coordinates.longitude;
                        if (
                          store.description &&
                          store.description.indexOf('Trofe') > -1 &&
                          !this.state.showStores
                        ) {
                          return null;
                        }
                        if (
                          !this.state.showOutlets &&
                          (!store.description ||
                            store.description.indexOf('Trofe') === -1)
                        ) {
                          return null;
                        }

                        return (
                          <Marker
                            key={index}
                            onClick={this.clickedMarker}
                            google={this.props.google}
                            position={{
                              lat: lat,
                              lng: lng
                            }}
                            icon={{
                              url: Pin,
                              scaledSize: new this.props.google.maps.Size(
                                20,
                                28
                              )
                            }}
                          />
                        );
                      })}
                    </Map>
                  </MapFrame>
                </Left>

                <Right narrow={narrow}>
                  <StoresSearch
                    stores={stores}
                    clickedStoreBox={this.clickedStoreBox}
                    showOutlets={this.state.showOutlets}
                    showStores={this.state.showStores}
                    handleStores={this.handleStores}
                    narrow={narrow}
                  />
                </Right>
              </StoreFinderWrapper>
            </Fragment>
          ) : null;
        }}
      </Query>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDs6SaF_2KuU6fjeJQZ1P2r1NzKAmG5Ggw'
})(Stores);

//API key: AIzaSyAf_U1II_sUS9FnkZCjxVleMsehOUZIczM
//API test key: AIzaSyAyesbQMyKVVbBgKVi2g6VX7mop2z96jBo
//API funkar AIzaSyAG4lg0UYO4fhMpCrGOwFTZDslSbN4EqAk
//API TROFES AIzaSyDs6SaF_2KuU6fjeJQZ1P2r1NzKAmG5Ggw
