import React from 'react';
import useDecrementQuantity from '@jetshop/core/components/Mutation/useDecrementQuantity';
import useIncrementQuantity from '@jetshop/core/components/Mutation/useIncrementQuantity';
import useRemoveFromCart from '@jetshop/core/components/Mutation/useRemoveFromCart';
import styled from 'react-emotion';
import Image from '@jetshop/ui/Image';
import theme from '@jetshop/ui/utils/theme';
import { Price } from '../Price';
import RemoveFromCart from '@jetshop/core/components/Mutation/RemoveFromCart';
import removeFromCartMutation from './removeFromCart.gql';
import incrementQuantityMutation from './incrementQuantity.gql';
import decrementQuantityMutation from './decrementQuantity.gql';
import cartQuery from './CartQuery.gql';
import { ReactComponent as Cross } from '../../svg/Close.svg';

const Wrapper = styled('div')`
  display: flex;
  background: white;
  margin-bottom: 0.5rem;
  > :first-child {
    flex: 1 1 4rem;
  }
  > :last-child {
    flex: 1 1 auto;
    padding: 0.5rem;
  }
`;

const ProductName = styled('h2')`
  color: #828282;
  font-size: 0.875rem;
  font-family: ${theme('fontFamilies.body')};
  font-weight: normal;
`;

const ProductDetail = styled('section')`
  display: grid;
  grid-auto-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: 'topleft topleft topleft topright' 'bottomleft bottomleft bottomright bottomright';
`;

const ItemDetails = styled('div')`
  grid-area: topleft;
`;

const LinePrice = styled(Price)`
  margin-top: 1rem;
  grid-area: bottomright;
  font-weight: 600;
  font-family: ${theme('fontFamilies.heavy')};
  text-align: right;
`;

const AdjustQty = styled('div')`
  display: flex;
  justify-content: space-between;
  max-width: 94px;
  margin: 1rem auto 0 auto;
  padding: 5px 0 2px 0;
  background ${theme('colors.grey')};
  /* Plus/minus buttons */
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    background ${theme('colors.grey')};
    color: black;
    :focus {
      outline: none;
    }
  }
  /* Count */
  span {
    margin: 0 0.5rem;
    display: inline-block;
  }
`;

const RemoveItem = styled('div')`
  grid-area: topright;
  text-align: right;
  button {
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
  }
  svg {
    fill: ${theme('colors.black')};
    height: 14px;
    width: 14px;
  }
`;

const CartItem = ({ item, className = '' }) => {
  const { incrementQuantity } = useIncrementQuantity({
    incrementQuantityMutation,
    cartQuery
  });

  const { decrementQuantity } = useDecrementQuantity({
    decrementQuantityMutation,
    cartQuery
  });

  const { removeFromCart } = useRemoveFromCart({
    removeFromCartMutation,
    cartQuery
  });
  return (
    <Wrapper className={className}>
      <div>
        {item.product.images.length > 0 && (
          <Image aspect="3:4" sizes="10rem" src={item.product.images} />
        )}
        <AdjustQty>
          <button
            disabled={item.quantity === 1}
            onClick={() =>
              item.quantity !== 1 && decrementQuantity({ itemId: item.id })
            }
          >
            -
          </button>

          <span>{item.quantity}</span>

          <button onClick={() => incrementQuantity({ itemId: item.id })}>
            +
          </button>
        </AdjustQty>
      </div>
      <ProductDetail>
        <ItemDetails>
          <ProductName>{item.product.name}</ProductName>
        </ItemDetails>

        <RemoveItem>
          <button
            onClick={() =>
              removeFromCart({ itemId: item.id, product: item.product })
            }
            data-testid="remove-from-cart"
          >
            <Cross />
          </button>
        </RemoveItem>

        <LinePrice price={item.total} />
      </ProductDetail>
    </Wrapper>
  );
};

export default CartItem;
